import React, {useState,useEffect} from 'react'
import {useParams,useNavigate} from "react-router-dom"

import { TwitterTweetEmbed } from 'react-twitter-embed'
import { useWindowSize } from 'usehooks-ts'


import Parallax from './../parallax.jsx'


export default function CgAnimated() {
  let params = useParams();
  const { width, height } = useWindowSize();
  const padding = 15
  const dim = width>height?height:width;
  const innerDim = dim-padding*2;

  const lastFrame = 4;
  const imagePieces = params.imgData.split("_");
  const gramId = imagePieces[0];
  const fgImage = imagePieces[1];
  const rotation = imagePieces[2];
  const bgImage = imagePieces[3];
  const colorR = imagePieces[4];
  const colorG = imagePieces[5];
  const colorB = imagePieces[6];
  const adjB = imagePieces[7];
  const adjC = imagePieces[8];
  const adjG = imagePieces[9];
  const tiled = imagePieces[10];

  const rgb = `rgb(${colorR},${colorG},${colorB})`;

  const [frame, setFrame] = useState(0);


  function handleScrollLeft(){
    if(frame>0){
      setFrame(frame-1);
    }
  }
  function handleScrollRight(){
    if(frame<lastFrame){
      setFrame(frame+1);
    }
  }
  const imgUrl = `https://dotmaps.wtf/workers/cg/v1/${params.imgData}/${params.tokenId}.png`;

  const leftButton = (
    <button className="btn btn-action s-circle bg-dark"
            onClick={handleScrollLeft}
            style={{position:"absolute",top:"50%",left:"0",zIndex:"9999999999",border:"1px solid #fff"}}>
      <i className="icon icon-arrow-left"></i>
    </button>
  );

  const rightButton = (
    <button className="btn btn-action s-circle bg-dark"
            onClick={handleScrollRight}
            style={{position:"absolute",top:"50%",right:"0",zIndex:"9999999999",border:"1px solid #fff"}}>
      <i className="icon icon-arrow-right"></i>
    </button>
  );

  const image = (
    <div style={{width:innerDim,height:innerDim,margin:"auto"}}>
      <img style={{width:innerDim,height:innerDim}} src={imgUrl} />
    </div>
  );

  // tweet with an overlay to prevent clicking
  const tweet = (
    <div style={{maxWidth:"550px",margin:"auto",position:"relative"}}>
      <img style={{position:"absolute",top:"0",left:"0",width:"100%",height:"100%"}} src="/data/candygrams/1x1.png" />

      <TwitterTweetEmbed tweetId={params.tweetId} placeholder="Tweet Loading..." options={{conversation: 'none' }}/>
    </div>
  );

  const data = (
    <div style={{maxWidth:"90%",margin:"auto",position:"relative"}}>
      <table class="table" >
        <tbody>
          <tr>
            <td colSpan="3" style={{textAlign:"center"}}>
              <strong>Token #{params.tokenId}</strong>
              <span> - Minted using CandyGram #{gramId}</span>
            </td>
          </tr>
          <tr>
            <th colSpan="3" style={{textAlign:"center",color:"#fff",backgroundColor:"#000"}}>Foreground</th>
          </tr>
          <tr>
            <td width="25%" style={{padding:"1px"}}>Image</td>
            <td width="25%" style={{padding:"1px"}}>{fgImage}</td>
            <td width="50%" style={{padding:"1px"}}>Set at mint</td>
          </tr>
          <tr>
            <td style={{padding:"1px"}}>Rotation</td>
            <td style={{padding:"1px"}}>{rotation}°</td>
            <td style={{padding:"1px"}}>Set at reveal</td>
          </tr>
          <tr>
            <td style={{padding:"1px"}}>Brightness</td>
            <td style={{padding:"1px"}}>
              <div>{adjB} of 16</div>
              <div style={{lineHeight:"1px"}}><progress className="progress" value={adjB} max="16"></progress></div>
            </td>
            <td style={{padding:"1px"}}>Set at reveal</td>
          </tr>
          <tr>
            <td style={{padding:"1px"}}>Contrast</td>
            <td style={{padding:"1px"}}>
              <div>{adjC} of 16</div>
              <div style={{lineHeight:"1px"}}><progress className="progress" value={adjC} max="16"></progress></div>
            </td>
            <td style={{padding:"1px"}}>Set at reveal</td>
          </tr>
          <tr>
            <td style={{padding:"1px"}}>Gamma</td>
            <td style={{padding:"1px"}}>
              <div>{adjG} of 16</div>
              <div style={{lineHeight:"1px"}}><progress className="progress" value={adjG} max="16"></progress></div>
            </td>
            <td style={{padding:"1px"}}>Set at reveal</td>
          </tr>
          <tr>
            <th colSpan="3" style={{textAlign:"center",color:"#fff",backgroundColor:"#000"}}>Background</th>
          </tr>
          <tr>
            <td style={{padding:"1px"}}>Pattern</td>
            <td style={{padding:"1px"}}>{bgImage}</td>
            <td style={{padding:"1px"}}>Set at reveal</td>
          </tr>
          <tr>
            <td style={{padding:"1px"}}>Color</td>
            <td style={{padding:"1px"}}>
              <div>{rgb}</div>
              <div style={{height:"15px",backgroundColor:rgb}}></div>
            </td>
            <td style={{padding:"1px"}}>Changes daily, the availible pallete increase the longer the token is held in a wallet</td>
          </tr>
          <tr>
            <td style={{padding:"1px"}}>Tiling</td>
            <td style={{padding:"1px"}}>{tiled}x{tiled}</td>
            <td style={{padding:"1px"}}>Decreases the more tokens a wallet holds</td>
          </tr>
        </tbody>
      </table>
    </div>
  );

  const physicalImgFront = "https://imagedelivery.net/J0STI0598EwiuC8K-WRYGA/d1bfe7f0-3c97-427e-621e-f3c0cc51bd00/public";
  const captionFront = `CandyGram #${gramId} - Front`;
  const front = (
    <div style={{margin:"auto",height:dim,width:dim,position:"relative",display:"flex",justifyContent:"center",alignItems:"center"}}>
      <Parallax img={physicalImgFront} maxWidth={innerDim*0.6} caption={captionFront} />
    </div>
  );

  const physicalImgBack = "https://imagedelivery.net/J0STI0598EwiuC8K-WRYGA/6298ddb9-7e85-4ae9-41f6-cd429f2d6b00/public";
  const captionBack = `CandyGram #${gramId} - Back`;
  const back = (
    <div style={{margin:"auto",height:dim,width:dim,position:"relative",display:"flex",justifyContent:"center",alignItems:"center"}}>
      <Parallax img={physicalImgBack} maxWidth={innerDim*0.6} caption={captionBack} />
    </div>
  );

  return (
    <div style={{height:dim,width:dim,position:"relative",textAlign:"center",margin:"0",padding:padding}}>
      {frame != 0 && leftButton}
      {frame != lastFrame && rightButton}


      {frame == 0 && image}
      {frame == 1 && tweet}
      {frame == 2 && data}
      {frame == 3 && front}
      {frame == 4 && back}

    </div>
  );
}
