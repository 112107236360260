import React, {useState,useEffect} from 'react'
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";

import { ConnectButton } from '@rainbow-me/rainbowkit';

import { parseEther,formatEther } from "@ethersproject/units";

function CgMintStep0(props) {

  return (
    <div>
      <p>
        You have just scanned CandyGram #{props.gramId}, welcome to the experiment that is CandyGrams. This is first and foremost a fun story telling journey so please be creative. We are excited for you to mint your very own digital CandyGram.  This minting process is a little bit different so please read all the instructions.
      </p>

      <p>
        In order mint a digital token you must first take a picture of the physical CandyGram and post it on twitter.  The post must include the hashtag <mark>#candygramnft</mark> and tag the <a href="https://twitter.com/dotmapswtf">@dotmapwtf</a> account.  Remember a link to this tweet will be stored on-chain with the token you are minting so be creative where you take the picture or with whom.  Tell the story of the digital avatar each CandyGram portrays.  Show them doing something new a different.  Make the story your own.
      </p>

      <div style={{textAlign:"center",padding:"15px auto"}}>
        <a href={props.clickToTweetUrl} target="_blank" className="btn btn-lg btn-success">Click to tweet your photo <i className="icon icon-photo"></i></a>
      </div>

      <p>
        Once you have sent a tweet please connect your wallet so you can connect that tweet and mint your NFT.
      </p>

      {!props.isConnected &&
        <div>
          <ConnectButton label="Connect Wallet to Mint" />
        </div>
      }

      {props.isConnected &&
        <div className="Step0Submit">
          <h5>Have you posted a picture of your physical CandyGram on twitter? You will need this link to mint.</h5>
          <div className="columns">
            <div className="col-6 col-sm-12">
              <button className="btn btn-lg btn-success" onClick={props.handleSubmit}>Yes! LFG 🚀🚀🚀🚀🚀</button>
            </div>
            <div className="col-6 col-sm-12">
              <Link to="/candygram" className="">No. I can't do this right now.<br/>I will do it later.</Link>
            </div>
          </div>
        </div>
      }

    </div>
  );

}

CgMintStep0.propTypes = {
  gramId: PropTypes.number.isRequired,
  handleSubmit: PropTypes.func.isRequired,
}

export default CgMintStep0;
