import React, {useState,useEffect} from 'react'
import { Link } from "react-router-dom";

import Navbar from './../navbar.jsx'
import Footer from './../footer.jsx'
import Divider from './../divider.jsx'
import Parallax from './../parallax.jsx'

import { useNetwork,useAccount,useContractRead,useBlockNumber } from 'wagmi'

import { useDmContract } from './../../hooks/dm-contract.js'

import cgData from "./candygram-data.json";



export default function CgIndex() {
  const [totalGrams,setTotalGrams] = useState(0);

  const [totalTokens,setTotalTokens] = useState(0);

  const {address:contractAddress, abi:contractAbi} = useDmContract("candygram");

  const { address, isConnecting, isDisconnected } = useAccount();


  const { isError: isTotalTokensError,
          isFetched: isTotalTokensLoaded
        } = useContractRead({
    address: contractAddress,
    abi: contractAbi,
    functionName: 'getTotalTokens',
    onSuccess(data) {
      //console.log('Success', data)
      setTotalTokens(data.toNumber());
    },
  })

  let gallery = [];
  cgData.physicals?.forEach(cg => {

    let img = "/data/candygrams/pcg/next.png"
    let url = ""
    let text = ""
    const caption = `more details`
    const key = `cg-gallery-key-${cg.id}`
    if(cg.front){
      img = `/data/candygrams/pcg/${cg.id}-front.png`;
      url = `/candygram/cg/${cg.id}`;
      text = <h3>{cg.name}</h3>;
    }

    gallery.push(
      <div key={key} className="col-3 col-md-6" style={{padding:"10px"}}>
        <Parallax img={img} caption={caption} link={url}>
          {text}
        </Parallax>
      </div>
    )
  });

  //<h5 className="bg-secondary" style={{textAlign:"center"}}>Current CandyGram Counts - Physical: {totalPCG} / Digital: {totalDCG}</h5>
  return (
    <div id="pageCandygram">
      <Navbar />
      <h1>CandyGrams</h1>
      <h5>Digital avatars traveling through the physical world with frens - an art experiment by CandyViz.</h5>

      <p>
        The goal of this project it to experiment with what an IRL social network looks like on the blockchain as well what can be done with physical art containing embedded technology.  Every mint should be shared with a new twitter post detailing the experience of each digital avatar. This is a fun experimental project so no guarantee of value, community or generational wealth is promised just enjoy the ride.  <span className="text-warning">As always be safe and cautious when minting anything.  Always use a minting/burner wallet to mint NFTs.</span>
      </p>

      <p>
        The CandyGram collection is the Web3 version of The Flat Stanley Project <a href="https://en.wikipedia.org/wiki/The_Flat_Stanley_Project"><i className="icon icon-link"></i></a>.
        CandyGrams are meant to be shared with frens either by mail OR IRL so after you mint one or many find a fren and
        pass the physical to them. Each Physical CandyGram has an digital tag in it that leads
        to a mint page where you can mint your digital CandyGram.  This is an NFT that can be sold & traded, but the
        NFT changes over time depending on who owns it.
      </p>

      <p>
        Part of the unique mint process requires a link to a tweet you have shared showing where the CandyGram
        has been (be creative where you take your photo and who you take your photo with). When you post a tweet
        of the CandyGram include a picture and the hashtag <strong>#candygramnft</strong> and
        tag <a href="https://twiter.com/dotmapswtf">@dotmapswtf</a>.
      </p>

      <div className="bg-dark" style={{textAlign:"center"}}>
        <h3 style={{padding:"10px"}}>Excited to see these digital avatars travel the world!</h3>
      </div>

      <div className="columns">
        {gallery}

      </div>

      <Divider />
      <Footer />
    </div>
  );
}
