import React, {useState,useEffect} from 'react'

import { useContractRead,useEnsName } from 'wagmi'

import { useDmContract } from './../hooks/dm-contract.js'

const logoOS = new URL('../images/opensea-logo.png', import.meta.url);
const logoEtherscan = new URL('../images/etherscan-logo.png', import.meta.url);
const logoLooksrare = new URL('../images/looksrare-logo.png', import.meta.url);

export default function TokenDetails(props) {
  //console.log("TokenDetails(props.token=",props.token,")");

  const {address:contractAddress, abi:contractAbi} = useDmContract(props.collection);

  const {data:owner, error:ownerOfError, isFetched:ownerOfIsFetched } = useContractRead({
    address: contractAddress,
    abi: contractAbi,
    functionName: 'ownerOf',
    args: [props.token],
  })

  const { data:ens, isError:ensNameIsError, isFetched:ensNameIsFetched } = useEnsName({
    address: owner,
  })


  const linkOpensea = `https://opensea.io/assets/ethereum/${contractAddress}/${props.token}`;
  const linkLooksrare = `https://looksrare.org/collections/${contractAddress}/${props.token}`;
  const linkEtherscan = `https://etherscan.io/token/${contractAddress}?a=${props.token}`;

  const logoStyle = {width:"20px",height:"20px",verticalAlign:"middle",margin:"0px 5px"};

  if (ownerOfError) {
    console.log(ownerOfError.message);
    return <div>⚠️ Error</div>;
  } else if (!ownerOfIsFetched) {
    return <div>Loading...</div>;
  } else if((typeof owner) == 'string' ) {
    const ownerLink = "https://etherscan.io/address/"+owner
    const addrShort = owner.replace(owner.substring(8,34), "...")
    return (
      <div style={{textAlign:"center",padding:"4px"}}>
        <div>
          <span style={{marginRight:"20px"}}>
          Owner:
            {!ens && <a href={ownerLink}>{addrShort}</a>}
            {ens && <a href={ownerLink}>{ens}</a>}
          </span>
          <a href={linkEtherscan}>
            <img src={logoEtherscan} style={logoStyle} />
          </a>
          <a href={linkOpensea}>
            <img src={logoOS} style={logoStyle} />
          </a>
          <a href={linkLooksrare}>
            <img src={logoLooksrare} style={logoStyle} />
          </a>
        </div>
      </div>
    );
  }
}
