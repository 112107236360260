import React, {useState,useEffect} from 'react'

import {useNavigate} from "react-router-dom"

import { useEffectOnce } from 'usehooks-ts'


export default function TwitterLogin(props) {
  const [loading, setLoading] = useState(false);

  function onBtnClick(){
    setLoading(true);
    fetch(`/api/twitter/request-token?host=local&linkKey=${props.linkKey}`)
    .then(response => response.json())
    .then((data) => {
      console.log(data)
      window.location.href = data.data.auth_url;

    })
  }

  let className = "btn btn-primary btn-lg";
  if(loading){
    className += " loading";
  }
  return (
    <div>
      <a className={className} onClick={onBtnClick}>
        Login with Twitter
      </a>
    </div>
  );
}
