import React, {useState,useEffect} from 'react'

import { useInterval } from 'usehooks-ts'


export default function CgMintWarning(props) {
  const [minTillExpire, setMinTillExpire] = useState("??");
  const [isMinTillExpireBlinking, setIsMinTillExpireBlinking] = useState(false);

  useInterval(
    () => {
      if(props.expTime != 0){
        const sec = (props.expTime - Date.now())/(1000);
        if(sec>0){
          setMinTillExpire(String(Math.floor(sec/60))+"min "+String(Math.floor(sec%60)).padStart(2, '0')+"sec");
        }else{
          setMinTillExpire("EXPIRED");
          props.onExpire();
        }

        if(sec<600){
          setIsMinTillExpireBlinking(true);
        }
      }
    },
    1000,
  );

  let expTimeClass = "";
  if(isMinTillExpireBlinking){
    expTimeClass = "blink";
  }
  return (
    <div style={{marginBottom:"10px"}}>
      <div className="bg-warning" style={{padding:"8px"}}>

        <span>🚨 This is a private minting page created for you, it expires in </span>
        <kbd className={expTimeClass}>{minTillExpire}</kbd>
      </div>
    </div>
  );
}
