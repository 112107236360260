import React from 'react'


export default function CgMintIntro(props) {
  return (
    <div>
      <p>
        The CandyGram you are holding is named <strong>{props.gramName}</strong>. It is on a journey through the world and looks forward to what ever adventure you would like to take it on.  Really <strong>{props.gramName}</strong> is game for anything.  Maybe take it sight seeing?  Take it to meet your family or someone famous? or just live your daily life and take it around with you. Live your best life and take some pictures of you and <strong>{props.gramName}</strong> enjoying the world around you.
      </p>

      <p>
        Once you have taken a picture with <strong>{props.gramName}</strong> tap it with your phone and a page like this will be created. Only one mint page can be created every hour.  Next you will be guided through how to post your experiences on twitter so that you can then mint a digital token that represents your time together.
      </p>

      <p className="text-warning" style={{border:"1px solid #ffb700",padding:"5px"}}>
        Security note: Do not share this link it is a private link for you.  The DotMaps website requests read-only access to your twitter account just in order to verify your ownership of it and that data is only stored for the length that this mint page exists then it it deleted.  We also suggest you use a burner or minting wallet to mint any NFT, including this one.
      </p>
    </div>
  );
}
