import React from 'react'
import { Link } from "react-router-dom";
//import img from "../images/dotmap-banner-smaller-text.png";

const imgBanner = new URL('../images/dotmap-banner-smaller.png', import.meta.url);

export default function Header() {
  return (
    <div id="section-header" className="text-uppercase text-center text-large p-2">
      Physical & Digital Art all using 1024 dots
    </div>
  );

}
