import React, { useState, useEffect } from 'react'

import {
         usePrepareContractWrite,
         useContractWrite,
         useWaitForTransaction
} from 'wagmi'

import { useDmContract } from './../../../hooks/dm-contract.js'


export default function CgMintPrepTransaction(props) {
  const [error, setError] = useState("");
  const [mintAttempt, setMintAttempt] = useState(0);


  useEffect(() => {
    if(!isMintLoading && props.mintAttempt != mintAttempt && ("signature" in props.sig)){
      mintTokenOnChain?.();
      setMintAttempt(props.mintAttempt);
    }

  },[props.mintAttempt])

  const {address:minterAddress, abi:minterAbi} = useDmContract("cgMinter");
  const {address:tokenAddress} = useDmContract("cgToken");
  const {address:renderAddress} = useDmContract("cgRender");

  const { config:pcwConfig, error:pcwError, isSuccess:isContractPrepared } = usePrepareContractWrite({
      address: minterAddress,
      abi: minterAbi,
      functionName: 'mint',
      args:[
        props.sig.to,
        parseInt(props.sig.gramId),
        props.sig.tweetId,
        props.sig.twitterUserId,
        props.sig.holder,
        props.sig.expTime,
        props.sig.signature
      ],
      overrides: {
        from: props.address,
        value: 0//gramMintPrice.toString(),
      },
      enabled:(("signature" in props.sig)),
      onError(error) {
        console.log(error)
        setError(error.message);
      },
    });

  const {
    data:mintWriteData,
    write:mintTokenOnChain,
    isLoading:isMintLoading,
    isSuccess:isMintSuccess
  } = useContractWrite(pcwConfig);

  return (
    <div>
      {error}


      <div>isLoading:{isMintLoading && "mint loading"}</div>
      <div>isMintSuccess:{isMintSuccess}</div>
      <p>Minter Contract: {minterAddress}</p>
      <p>Token Contract: {tokenAddress}</p>
      <p>Render Contract: {renderAddress}</p>
      <p>Tx:{mintWriteData?.hash}</p>

    </div>
  );
}
