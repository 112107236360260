[
	{
		"inputs": [
			{
				"internalType": "string",
				"name": "ext",
				"type": "string"
			},
			{
				"internalType": "string",
				"name": "img",
				"type": "string"
			},
			{
				"internalType": "string",
				"name": "anim",
				"type": "string"
			}
		],
		"stateMutability": "nonpayable",
		"type": "constructor"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "previousOwner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "OwnershipTransferred",
		"type": "event"
	},
	{
		"inputs": [],
		"name": "animBaseUrl",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "enableAnim",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "externalBaseUrl",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "imageBaseUrl",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "owner",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"components": [
					{
						"internalType": "uint256",
						"name": "tokenId",
						"type": "uint256"
					},
					{
						"internalType": "uint8",
						"name": "gramId",
						"type": "uint8"
					},
					{
						"internalType": "string",
						"name": "gramName",
						"type": "string"
					},
					{
						"internalType": "uint256",
						"name": "gramStartBlock",
						"type": "uint256"
					},
					{
						"internalType": "uint256",
						"name": "gramStopBlock",
						"type": "uint256"
					},
					{
						"internalType": "uint256",
						"name": "tweetId",
						"type": "uint256"
					},
					{
						"internalType": "bytes32",
						"name": "renderKey",
						"type": "bytes32"
					},
					{
						"internalType": "uint256",
						"name": "renderBlock",
						"type": "uint256"
					},
					{
						"internalType": "bytes32",
						"name": "delayedRenderKey",
						"type": "bytes32"
					},
					{
						"internalType": "uint256",
						"name": "prevGramToken",
						"type": "uint256"
					},
					{
						"internalType": "uint256",
						"name": "mintBlock",
						"type": "uint256"
					},
					{
						"internalType": "address",
						"name": "owner",
						"type": "address"
					},
					{
						"internalType": "uint256",
						"name": "lastTransferBlock",
						"type": "uint256"
					},
					{
						"internalType": "uint256",
						"name": "addressTokenCount",
						"type": "uint256"
					},
					{
						"internalType": "uint256",
						"name": "totalTokenCount",
						"type": "uint256"
					},
					{
						"internalType": "uint256",
						"name": "gramSeqNum",
						"type": "uint256"
					}
				],
				"internalType": "struct ICGRender.RenderData",
				"name": "d",
				"type": "tuple"
			}
		],
		"name": "render",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "renounceOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "string",
				"name": "url",
				"type": "string"
			},
			{
				"internalType": "bool",
				"name": "enable",
				"type": "bool"
			}
		],
		"name": "setAnimBaseUrl",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "string",
				"name": "url",
				"type": "string"
			}
		],
		"name": "setExternalBaseUrl",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "string",
				"name": "url",
				"type": "string"
			}
		],
		"name": "setImageBaseUrl",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "toggleEnableAnim",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "transferOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	}
]
