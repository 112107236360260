import React, {useState,useEffect} from 'react'
import PropTypes from 'prop-types';

import cgData from "./candygram-data.json";


//const imgRot = ["0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","90","180","270"];
const imgRot = ["0","90","180","270"];

const gramsReady = ["1","2","3","4","5","10","11","12","13","14","15"];


function genRandSeeds(num) {
  let data = [];
  for(let i = 0; i<num ; i++){
    data.push(Math.floor(Math.random()*4391*3529+i))
  }
  return data;
}

function CgSampleGallery(props) {

  const [seed, setSeed] = useState(genRandSeeds(props.count*12));
  const [modalImage, setModalImage] = useState("");

  function handleImageClick(url){
    setModalImage(url);
  }

  function handleModalClose(){
    setModalImage("");
  }

  function imageUrl(gram,img,rot,bg,r,g,b,ab,ac,ag,tiled){
    return `https://dotmaps.wtf/workers/cg/v1/${gram}_${img}_${rot}_${bg}_${r}_${g}_${b}_${ab}_${ac}_${ag}_${tiled}/bg.png`;
  }

  function imageUrlv2(gram,t1,t2,t3,t4,t5,t6,r,g,b,bg,tiled){
    return `https://dotmaps.wtf/workers/cg/v2/${gram}_${t1}_${t2}_${t3}_${t4}_${t5}_${t6}_${r}_${g}_${b}_${bg}_${tiled}/bg.png`;
  }


  const colStyle = "column stuff "+props.colStyle;

  const bgNames = cgData.bg;
  const fgNames = cgData.fg;
  const traitNames = cgData.traits;
  const imgs = [];
  for (let i = 0; i < props.count; i++) {
    const gram = props.gramId ? props.gramId : gramsReady[seed[i+0]%gramsReady.length];
    const t1 = traitNames[seed[i+1]%traitNames.length];
    const t2 = traitNames[seed[i+2]%traitNames.length];
    const t3 = traitNames[seed[i+3]%traitNames.length];
    const t4 = traitNames[seed[i+4]%traitNames.length];
    const t5 = traitNames[seed[i+5]%traitNames.length];
    const t6 = traitNames[seed[i+6]%traitNames.length];
    const cR = seed[i+7]%255;
    const cG = seed[i+8]%255;
    const cB = seed[i+9]%255;
    const bg = bgNames[seed[i+10]%bgNames.length];
    const tiled = seed[i+11]%6;
    const url = imageUrlv2(gram,t1,t2,t3,t4,t5,t6,cR,cG,cB,bg,tiled);
    const key = `bgp-img-key-${i}`;
    imgs.push(
      <div key={key} className={colStyle} style={{padding:"5px"}}>
        <a onClick={() => handleImageClick(url)}>
          <img className="img-responsive" src={url}/>
        </a>
      </div>
    );
  }



  let modal = "";
  if(modalImage != ""){
    //const url = imageUrl(modalImage,bgColorR,bgColorG,bgColorB,bgTiled);
    modal = (
      <div className="modal modal-lg active" id="modal-id">
        <a className="modal-overlay" aria-label="Close" onClick={handleModalClose}></a>
        <div className="modal-container" style={{maxHeight:"95vh"}}>
          <div className="modal-header">
            <a className="btn btn-clear float-right" aria-label="Close" onClick={handleModalClose}></a>
          </div>
          <div className="modal-body">
            <div className="content" style={{textAlign:"center"}}>
              <img className="img-responsive" src={modalImage} style={{margin:"0 auto",maxHeight:"85vh",maxWidth:"85vh"}}/>
            </div>
          </div>
        </div>
      </div>
    );
  }




  return (
    <div id="sampleGallery">
      {props.header &&
        <h5>{props.header}</h5>
      }

      {props.subheader &&
        <p>{props.subheader}</p>
      }

      {modal}

      <div className="columns">
        {imgs}
      </div>

    </div>
  );
}

CgSampleGallery.propTypes = {
  count: PropTypes.number.isRequired,
  colStyle: PropTypes.string.isRequired,
}

export default CgSampleGallery;
