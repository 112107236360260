import React, {useState,useEffect} from 'react'

import Navbar from './navbar.jsx'
import Footer from './footer.jsx'
import Divider from './divider.jsx'

const files = ["Ability","Country","Goal","Meaning","Problem","Television","Activity","Data","Growth","Meat","Product","Temperature","Addition","Decision","Health","Media","Quality","Thanks","Analysis","Definition","History","Medicine","Reading","Theory","Area","Department","Idea","Method","Reality","Thing","Army","Development","Income","Moment","Road","Thought","Art","Direction","Industry","Mood","Safety","Truth","Article","Disk","Information","Movie","Science","Unit","Audience","Distribution","Instance","Nation","Security","University","Basis","Driver","Internet","Nature","Series","User","Bird","Economics","Knowledge","News","Shopping","Variety","Blood","Education","Lake","Night","Skill","Video","Boyfriend","Energy","Language","Organization","Society","War","Camera","Equipment","Law","Oven","Software","Week","Cell","Event","Length","Paper","Soup","Wood","Chemistry","Exam","Library","People","Story","World","Child","Fact","Location","Person","Strategy","Writing","Combination","Failure","Love","Philosophy","Student","Community","Family","Management","Physics","Success","Computer","Fishing","Marketing","Player","System","Context","Food","Marriage","Policy","Teacher","Control","Freedom","Math","Power","Technology","Depth"];


export default function BgPatterns() {
  const total = files.length;

  const perPage = 16;
  const pageCount = total / perPage;


  const [bgColorR, setBgColorR] = useState(0);
  const [bgColorG, setBgColorG] = useState(0);
  const [bgColorB, setBgColorB] = useState(0);
  const [bgHex, setBgHex] = useState("#000000");
  const [validHex, setValidHex] = useState(true);
  const [bgTiled, setBgTiled] = useState(1);
  const [page, setPage] = useState(0);
  const [modalImage, setModalImage] = useState("");
  const [nameFilter, setNameFilter] = useState("");
  const [fileList, setFileList] = useState(files);


  function handleHexChange(event){
    let hex = event.target.value.toLowerCase();
    const m = hex.match(/^#([0-9a-f]{2})([0-9a-f]{2})([0-9a-f]{2})$/i);
    if(!!m){
      setBgColorR(parseInt(m[1],16));
      setBgColorG(parseInt(m[2],16));
      setBgColorB(parseInt(m[3],16));
      setValidHex(true);
    }else{
      setValidHex(false);
      if(hex.length <= 1){
        hex = "#";
      }else{
        hex = "#" + hex.substring(1, 7).replace(/[^0-9a-f]/,'');
      }
    }
    setBgHex(hex);
  }

  function handleImageClick(name){
    setModalImage(name);
  }

  function handlePageClick(num){
    setPage(num);
  }

  function handleModalClose(){
    setModalImage("");
  }

  function handleTiledChange(event){
    const tiled = event.target.value;
    setBgTiled(tiled);
  }

//          <input className="form-input" type="text" placeholder="Find by Name" value={nameFilter} onChange={handleFilterChange} />
  function handleFilterChange(event){
    const filter = event.target.value;
    setNameFilter(filter);
  }

  useEffect(() => {
    setFileList(files.filter(name => name.toLowerCase().indexOf(nameFilter.toLowerCase()) > -1));
    setPage(0);
  },[nameFilter])


  function imageUrl(name,r,g,b,tiled){
    return `https://dotmaps.wtf/workers/bgp/v2/${name}_${r}_${g}_${b}_${tiled}/bg.png`;
  }

  const imgs = [];
  const start = page * perPage;
  const end = start + perPage;
  for (let i = start; i < end; i++) {
    const name = fileList[i];
    const url = imageUrl(name,bgColorR,bgColorG,bgColorB,bgTiled);
    const key = `bgp-img-key-${i}`;
    imgs.push(
      <div key={key} className="column col-3 col-xs-6" style={{padding:"5px"}}>
        <a onClick={() => handleImageClick(name)}>
          <img className="img-responsive" src={url} />
        </a>
      </div>
    );
  }

  const pages = [];
  const prevPage = page - 1;
  const nextPage = page + 1;
  pages.push(
    <li className={ prevPage <= -1 ? "page-item disabled":"page-item" } style={{padding:0,margin:0}}>
      <a tabindex="-1" onClick={() => handlePageClick(prevPage)}>Previous</a>
    </li>
  )
  for (let i = 0; i < pageCount; i++) {
    const pageNum = i + 1;
    pages.push(
      <li className={ page == i ? "page-item active" : "page-item" } style={{padding:0,margin:0}}>
        <a onClick={() => handlePageClick(i)}>{pageNum}</a>
      </li>
    )
  }
  pages.push(
    <li className={ nextPage >= pageCount ? "page-item disabled":"page-item" } style={{padding:0,margin:0}}>
      <a onClick={() => handlePageClick(nextPage)}>Next</a>
    </li>
  )



  let modal = "";
  if(modalImage != ""){
    const url = imageUrl(modalImage,bgColorR,bgColorG,bgColorB,bgTiled);
    modal = (
      <div className="modal active" id="modal-id">
        <a className="modal-overlay" aria-label="Close" onClick={handleModalClose}></a>
        <div className="modal-container">
          <div className="modal-header">
            <a className="btn btn-clear float-right" aria-label="Close" onClick={handleModalClose}></a>
            <div className="modal-title h5">{modalImage}</div>
          </div>
          <div className="modal-body">
            <div className="content">
              <img className="img-responsive" src={url} />
            </div>
          </div>
        </div>
      </div>
    );
  }


  return (
    <div id="pageBgPatterns">
      <Navbar />
      <h1>Patterns</h1>
      <p>
        128 custom made patterns in exploration of color, form & repetition.
      </p>

      {modal}

      <div className="container">
        <div className="columns">
          <div className="input-group col-3 col-sm-12 has-icon-right">
            <span className="input-group-addon">BG</span>

              <input className="form-input" type="text" value={bgHex} onChange={handleHexChange} />
              {validHex && <i className="form-icon icon icon-check" style={{color:"green"}}></i>}
              {!validHex && <i className="form-icon icon icon-stop" style={{color:"red"}}></i>}

          </div>
          <div className="input-group col-2 col-sm-12 col-mx-auto">
            <span className="input-group-addon">Tile</span>

            <select className="form-select" value={bgTiled} onChange={handleTiledChange}>
              <option value="1">None</option>
              <option value="2">2x2</option>
              <option value="3">3x3</option>
              <option value="4">4x4</option>
              <option value="5">5x5</option>
              <option value="6">6x6</option>
            </select>

          </div>


          <div className="col-6 col-sm-12 col-ml-auto">

              <ul className="pagination" style={{padding:0,margin:0}}>{pages}</ul>
          </div>
        </div>

        <div className="columns">
          {imgs}
        </div>

      </div>


      <Divider />
      <Footer />
    </div>
  );
}
