import React, { useState } from 'react';
import { Link } from "react-router-dom";

import TinyGenesisImage from './tiny-genesis-image.jsx'

const shuffleArray = array => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    const temp = array[i];
    array[i] = array[j];
    array[j] = temp;
  }
}

export default function TinyGenesisGallery() {
  const [value, setValue] = useState();
  const refresh = ()=>{ setValue({}); }

  const tokens = []
  for (let i = 0; i < 64; i++) {
    tokens.push(i);
  }
  shuffleArray(tokens);

  const images = [];
  for (let i = 0; i < 12; i++) {
    const token = tokens[i]+1
    const key = "tiny-gallery-key-"+token
    images.push(<TinyGenesisImage key={key} token={token} />);
  }
  return (
    <div id="section-tiny-genesis-gallery">
      <div>12 random DotMaps (<a onClick={refresh}>refresh</a> / <Link to="/genesis">view all</Link>)</div>
      <div className="columns">
        {images}
      </div>
    </div>
  );
}
