import React from 'react'


export default function Bcbcbh() {
  return (

    <div className="bcbcbh">
      <div className="s-circle">
        Be curious,<br />
        be cautious,<br />
        be happy. ❤️
      </div>
    </div>
  );
}
