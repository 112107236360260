import { useState, useEffect } from 'react';

import { chain, useNetwork } from 'wagmi';

import dotmapGenesisAbi from '../../abi/dotmap-genesis-abi-partial.json'
import candygramAbi_v1 from '../../abi/candygram-abi-v1-mainnet.json';
import candygramAbi_v2 from '../../abi/candygram-abi-v2-testnet.json';
import cgRenderAbi_v1 from '../../abi/cg-render-v1.json';
import cgTokenAbi_v1 from '../../abi/cg-token-v1.json';
import cgMinterAbi_v1 from '../../abi/cg-minter-v1.json';

const contracts = {
  genesis: {
    1: {
      address: "0x7f99A36556a05C80E2C30D8663a7Ff6eC69022cB",
      abi: dotmapGenesisAbi,
    },
    5: {
      address: "0x6eabf013fdce2a8b66442d23d8c0c1c61b8708e5",
      abi: dotmapGenesisAbi,
    }
  },
  candygram: {
    1: {
      address: "0x2f85428f0dc2391c0537d342d7d7434a72476982",
      abi: candygramAbi_v1,
    },
    5: {
      address: "0x76089Dc9a7a00e637a63D5CAEFcEd72E3f276176",
      abi: candygramAbi_v2,
    }
  },
  candygramRender: {
    1: {
      address: "0x03825163fe1305d7ec2d3cce6c9ed84aab10a5cd",
      abi: candygramAbi_v1,
    },
    5: {
      address: "0x2f85428F0dC2391c0537d342D7D7434A72476982",
      abi: candygramAbi_v2,
    }
  },
  cgMinter: {
    1: {
      address: "",
      abi: cgMinterAbi_v1,
    },
    5: {
      address: "0x7fBd416a1aaE0E7B726EFc0D47e5e1b76690B0E2",
      abi: cgMinterAbi_v1,
    },
    31337: {
      address: "0x7bc06c482DEAd17c0e297aFbC32f6e63d3846650",
      abi: cgMinterAbi_v1,
    },
  },
  cgToken: {
    1: {
      address: "",
      abi: cgTokenAbi_v1,
    },
    5: {
      address: "0xdFD5EC429D29dd8c94F088128a165E6E47D6464E",
      abi: cgTokenAbi_v1,
    },
    31337: {
      address: "0x7969c5eD335650692Bc04293B07F5BF2e7A673C0",
      abi: cgTokenAbi_v1,
    },
  },
  cgRender: {
    1: {
      address: "",
      abi: cgRenderAbi_v1,
    },
    5: {
      address: "0x6FDe06E2DDE87C344261D879Ebf750dC149d28a1",
      abi: cgRenderAbi_v1,
    },
    31337: {
      address: "0x2bdCC0de6bE1f7D2ee689a0342D76F52E8EFABa3",
      abi: cgRenderAbi_v1,
    },
  }
};

export function useDmContract(key) {
  const { chain, chains } = useNetwork();

  let response = {};
  let chainId = 1

  if(chain){
    chainId = chain.id;
  }

  if(key in contracts){
    if(chainId in contracts[key]){
      response = contracts[key][chainId];
    }
  }

  return response;
}
