import React from 'react'




export default function CgMintSendTweet(props) {

  const tweetSuggestionBody = `${props.gramName} \n\n#candygramnft`
  const clickToTweetUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(tweetSuggestionBody)}&via=dotmapswtf`

  const randNum = Math.floor(Math.random() * 4)+1;

  const imgPath = `/data/candygrams/mint/tweet-example${randNum}.png`

  return (
    <div className="columns">
      <div className="column col-6">
        <p>Share your picure of <strong>{props.gramName}</strong> on twitter. </p>
        <div>
          The tweet must include following things:
        <ol>
          <li>The name <strong>{props.gramName}</strong></li>
          <li>Hashtag <code>#candygramnft</code></li>
          <li>Mention <code>@dotmapswtf</code></li>
          <li>An image of the CandyGram</li>
        </ol>
        </div>
        <div>
          Use the button below to format a tweet with all the correct info.  Click the button below and add the image and a story.
        </div>

        <div style={{textAlign:"center",padding:"15px"}}>
          <a href={clickToTweetUrl} className="btn btn-lg btn-primary" target="_blank">Click to Tweet</a>
        </div>
      </div>
      <div className="column col-6" style={{textAlign:"center",padding:"15px"}}>
        <img className="img-responsive" src={imgPath} alt="Example Tweet"/>
      </div>

    </div>
  );
}
