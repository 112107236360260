import React, {useState,useEffect} from 'react'
import {useParams,useNavigate,Link} from "react-router-dom"

import { TwitterTweetEmbed } from 'react-twitter-embed'
import { useInterval } from 'usehooks-ts'

import { useAccount,
         useSignMessage,
         usePrepareContractWrite,
         useContractWrite,
         useWaitForTransaction,
         useContractRead
} from 'wagmi'
import { parseEther,formatEther } from "@ethersproject/units";

import { useDmContract } from './../../hooks/dm-contract.js'

import Navbar from './../navbar.jsx'
import Footer from './../footer.jsx'
import Divider from './../divider.jsx'
import CgMintError from './mint-error.jsx'
import CgMintStep0 from './mint-step0.jsx'
import CgMintStep2 from './mint-step2.jsx'
import CgMintStep3 from './mint-step3.jsx'
import CgMintSuccess from './mint-success.jsx'



export default function CgMint() {
  const { address, isConnected } = useAccount();

  let params = useParams();
  const navigate = useNavigate();
  const [gramId, setGramId] = useState(0);
  const [gramName, setGramName] = useState("");
  const [expTime, setExpTime] = useState(0);
  const [minTillExpire, setMinTillExpire] = useState("??");
  const [isMinTillExpireBlinking, setIsMinTillExpireBlinking] = useState(false);

  const [mintLinkKey, setMintLinkKey] = useState(params.mintLinkKey);
  const [isMintLinkKeyChecked, setIsMintLinkKeyChecked] = useState(false);
  const [isMintLinkKeyValid, setIsMintLinkKeyValid] = useState(false);
  const [isMintLinkKeyExpired, setIsLinkKeyExpired] = useState(false);

  const [mintError, setMintError] = useState('');
  const [hasMintError, setHasMintError] = useState(false);

  const [isReadyToStartMint, setIsReadyToStartMint] = useState(false);

  const [isGramMintable, setIsGramMintable] = useState(false);
  const [gramMintPrice, setGramMintPrice] = useState(BigInt(0));
  const [gramTokenLimit, setGramTokenLimit] = useState(0);
  const [gramTokenCount, setGramTokenCount] = useState(0);



  // step 1
  const [mintCode, setMintCode] = useState('10000000-0000-0000-0000-000000000000');
  const [isMintCodeChecked, setIsMintCodeChecked] = useState(false);
  const [isMintCodeValid, setIsMintCodeValid] = useState(false);
  const [isMintCodeFormatted, setIsMintCodeFormatted] = useState(false);
  const [isMintCodeSubmitted, setIsMintCodeSubmitted] = useState(false);

  // step 2
  const [tweetId, setTweetId] = useState('');
  const [isTweetIdChecked, setIsTweetIdChecked] = useState(false);
  const [isTweetIdValid, setIsTweetIdValid] = useState(false);
  const [isTweetIdFormatted, setIsTweetIdFormatted] = useState(false);
  const [isTweetIdMissingInfo, setIsTweetIdMissingInfo] = useState(true);
  const [isTweetIdSubmitted, setIsTweetIdSubmitted] = useState(false);
  const [isTweetUsedPreviously, setIsTweetUsedPreviously] = useState(false);
  const [tweetWarningMsg, setTweetWarningMsg] = useState('');

  const [mintSig, setMintSig] = useState({});
  const [mintSubmit, setMintSubmit] = useState(false);
  const [mintTransactionHash, setMintTransactionHash] = useState(false);


  const {address:contractAddress, abi:contractAbi} = useDmContract("candygram");

  const {
    isFetched:fetchedCheckIsGramMintable
  } = useContractRead({
    address: contractAddress,
    abi: contractAbi,
    functionName: 'checkIsGramMintable',
    args: [gramId],
    watch: true,
    onSuccess(data) {
      console.log('checkIsGramMintable():', data)
      setIsGramMintable(data);
    },
    onError(error) {
      setHasMintError(true);
      console.log(error)
      setMintError(error);
    }
  });

  const {
    isFetched:fetchedGetGramMintPrice
  } = useContractRead({
    address: contractAddress,
    abi: contractAbi,
    functionName: 'getGramMintPrice',
    args: [gramId],
    watch: true,
    onSuccess(data) {
      console.log('getGramMintPrice():', data)
      setGramMintPrice(data);
    },
    onError(error) {
      setHasMintError(true);
      console.log(error)
      setMintError(error);
    }
  });

  const {
    isFetched:fetchedGetGramData
  } = useContractRead({
    address: contractAddress,
    abi: contractAbi,
    functionName: 'getGramData',
    args: [gramId],
    onSuccess(data) {
      console.log('Success', data)
      if(data.name.length>0){
        setGramName(data[1]);
        setGramTokenLimit(data[4]);
        setGramTokenCount(data[0][0].toNumber());
      }
    },
  })

  const {
    isFetched:fetchedGetTokenByTweetId
  } = useContractRead({
    address: contractAddress,
    abi: contractAbi,
    functionName: 'getTokenByTweetId',
    args: [tweetId],
    enabled: tweetId,
    watch: true,
    onSuccess(data) {
      console.log('getTokenByTweetId():', data);
      if(data>0){
        setIsTweetIdValid(false);
        setIsTweetUsedPreviously(true);
        setTweetWarningMsg("Tweet already associated with a previous token");
      }else{
        setIsTweetUsedPreviously(false);
      }
    },
    onError(error) {
      setHasMintError(true);
      console.log(error)
      setMintError(error);
    }
  });


  const { config:pcwConfig, error:pcwError, isSuccess:isContractPrepared } = usePrepareContractWrite({
      address: contractAddress,
      abi: contractAbi,
      functionName: 'mint',
      args:[ address, parseInt(gramId), tweetId, parseInt(expTime), 0, 0, mintSig.signature],
      overrides: {
        from: address,
        value: gramMintPrice.toString(),
      },
      enabled:(("signature" in mintSig) && isTweetIdSubmitted),
      onError(error) {
        setHasMintError(true);
        console.log(error)
        setMintError(error.message);
      },
    });

  const {
    data:mintWriteData,
    write:mintTokenOnChain,
    isLoading:isMintLoading,
    isSuccess:isMintSuccess
  } = useContractWrite(pcwConfig);


  const {
    data: mintTransData,
    error: mintTransError,
    isLoading: isMintTransLoading,
    isFetching: isMintTransFetching,
    isSuccess: isMintTransSuccess,
    isError: isMintTransError,
  } = useWaitForTransaction({
    hash: mintWriteData?.hash,
    onSuccess(data) {
      //isMintTransSuccess(true);
      console.log('Success', data)
      setMintTransactionHash(data.transactionHash)
    },
    onError(error) {
      //isMintTransError(true);
      console.log('Error', error)
    },
  })


  function refreshMintData(mlk,mc,tid,addr){
    const qs = `linkKey=${mlk}&mintCode=${mc}&tweetId=${tid}&address=${addr}`
    fetch(`/api/candygram/prepare-mint?${qs}`)
    .then(response => response.json())
    .then((data) => {

      setIsMintLinkKeyChecked(true);
      if(("valid" in data) && data.valid){
        setIsMintLinkKeyValid(true);
        setGramId(data.gramId);
        setExpTime(data.expTime);
        if(("valid" in data.code) && data.code.valid){
          setIsMintCodeValid(true);
        }else{
          setIsMintCodeValid(false);
        }
        if(("valid" in data.tweet) && data.tweet.valid){
          setTweetWarningMsg("");
          setIsTweetIdValid(true);
        }else if(("error" in data.tweet) && data.tweet.error=="TWEET-ID-NOT-VALID"){
          setTweetWarningMsg("Tweet doesn't exist");
          setIsTweetIdValid(false);
          setIsTweetIdMissingInfo(false);
        }else if(("error" in data.tweet) && data.tweet.error=="TWEET-ID-MISSING-HT"){
          setTweetWarningMsg("Tweet missing hashtag #candygramnft");
          setIsTweetIdValid(false);
          setIsTweetIdMissingInfo(true);
        }else if(("error" in data.tweet) && data.tweet.error=="TWEET-ID-MISSING-AT"){
          setTweetWarningMsg("Tweet missing @dotmapswtf");
          setIsTweetIdValid(false);
          setIsTweetIdMissingInfo(true);
        }else{
          setTweetWarningMsg("Problem validating Tweet");
          setIsTweetIdValid(false);
          setIsTweetIdMissingInfo(false);
        }
      }else if("error" in data){
        setIsMintLinkKeyValid(false);
        setHasMintError(true);
        if(data.error=="NOT-LATEST-LINK-KEY"){
          setIsMintLinkKeyValid(false);
          setMintError("This mint link has been replaced ♻️. Only the most recent link is supported. Please try again.");
        }else if(data.error=="NFC-MISSING"){
          setIsMintLinkKeyValid(false);
          setMintError("This mint link has expired ⏰. Please try again.");
        }else if(data.error=="LINK-KEY-NOT-VALID"){
          setIsMintLinkKeyValid(false);
          setMintError("This mint link is not valid. Please try again.");
        }else{
          setMintError("Error: "+data.error);
        }
      }

      if("sig" in data){
        setMintSig(data.sig);
      }
    })
  }

  useEffect(() => {
    refreshMintData(mintLinkKey,mintCode,tweetId,address);
  },[mintLinkKey,isMintLinkKeyExpired,address])

  function handleTweetChange(tId){
    tId = tId.toString();
    tId = tId.replace(/[?].*$/,'');
    tId = tId.replace(/^https:\/\/twitter.com\/.+\/status\//,'');
    tId = tId.replaceAll(/[^0-9]*/g,'');

    const format = !!tId.match(/^[0-9]{19,}$/);

    setTweetId(tId);
    setTweetWarningMsg("");
    if(format){
      setIsTweetIdChecked(true);
      setIsTweetIdFormatted(true);
      refreshMintData(mintLinkKey,mintCode,tId,address);
    }else{
      setIsTweetIdChecked(true);
      setIsTweetIdFormatted(false);
      setIsTweetIdValid(false);
    }

    if(tId.length==0){
      setIsTweetIdChecked(false);
      setIsTweetIdFormatted(false);
      setIsTweetIdValid(false);
    }
  }

  function handleMintCodeChange(event){
    const c = event.target.value;
    const format = !!c.match(/^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi);
    setMintCode(c);
    if(format){
      setIsMintCodeChecked(true);
      setIsMintCodeFormatted(true);
      refreshMintData(mintLinkKey,c,tweetId,address);
    }else{
      setIsMintCodeChecked(true);
      setIsMintCodeFormatted(false);
      setIsMintCodeValid(false);
    }

    if(c.length==0){
      setIsMintCodeChecked(false);
      setIsMintCodeValid(false);
      setIsMintCodeFormatted(false);
    }
  }

  function handleIncFutureMintPriceChange(){
    setIncFutureMintPrice(!incFutureMintPrice);
  }

  function handleSubmitStep0(){
    setIsReadyToStartMint(true);
  }

  function handleSubmitStep1(){
    console.log(isMintCodeChecked,isMintCodeValid);
    setIsMintCodeSubmitted(isMintCodeValid);
  }

  function handleSubmitStep2(){
    console.log(isTweetIdChecked,isTweetIdValid);
    setIsTweetIdSubmitted(isTweetIdValid);
  }

  function handleBackToSubmitStep2(){
    setIsTweetIdSubmitted(false);
  }

  function handleSubmitMint(){
    setMintSubmit(true);
    console.log("Mint: ",gramId,tweetId,mintCode);
    //signMintLinkKey();
    mintTokenOnChain?.();
  }

  // useEffect(() => {
  //   console.log("Signed MintLinkKey",signedMintLinkKey);
  // },[signedMintLinkKey])

  useInterval(
    () => {
      if(expTime != 0){
        const sec = (expTime - Date.now())/(1000);
        if(sec>0){
          setMinTillExpire("expires in "+ String(Math.floor(sec/60))+"min "+String(Math.floor(sec%60)).padStart(2, '0')+"sec");
        }else{
          setMinTillExpire("expired");
          if(!mintSubmit){
            setIsLinkKeyExpired(true);
          }
        }

        if(sec<600){
          setIsMinTillExpireBlinking(true);
        }
      }
    },
    1000,
  );



  const mintButtonDisabled = !(isTweetIdValid && isMintCodeValid && isContractPrepared);

  let title = "";
  if(isMintLinkKeyValid){
    title = <h2>CandyGram #{gramId}</h2>
  }

  const tweetSuggestionBody = `${gramName} #candygramnft`
  const clickToTweetUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(tweetSuggestionBody)}&via=dotmapswtf`


  let expTimeClass = "expTime";
  if(isMinTillExpireBlinking){
    expTimeClass = "expTime blink";
  }

  let content = <div className="loading loading-lg"></div>;
  let warning = (
    <div>
      <h5 className="bg-warning">
        <span className="text-error">Do not share</span><br/>
        <span>⚠️ This is a private minting page created by CandyGram #{gramId}</span><br/>
        <kbd className={expTimeClass}>{minTillExpire}</kbd>
      </h5>
      <h5 className="bg-secondary">
        {gramName} ({gramTokenCount} of {gramTokenLimit} minted) - current mint price is {gramMintPrice!=0?formatEther(gramMintPrice)+"eth":"free"} + gas
      </h5>
    </div>
  );


  if(isMintTransSuccess){
    warning = "";
    content = (
      <CgMintSuccess
            tweetId={tweetId}
            address={address}
            contractAddress={contractAddress}
            gramId={gramId}
            mintTransactionHash={mintTransactionHash}
      />
    );
  }else if(hasMintError){
    content = <CgMintError mintError={mintError} />;
    warning = "";
  }else if(!fetchedCheckIsGramMintable){
    content = <div><p>Fetching...</p><div className="loading loading-lg"></div></div>;
  }else if(fetchedCheckIsGramMintable && !isGramMintable){
    warning = "";
    content = (
      <div>
        <p>CandyGram not mintable at this time.</p>
        <Link to={"/candygram/cg/"+gramId}>Read more about this CandyGram</Link>
      </div>
    );
  }else if(isMintLinkKeyValid && isConnected && isReadyToStartMint && isTweetIdSubmitted ){
    content = (
      <CgMintStep3
            tweetId={tweetId}
            address={address}
            contractAddress={contractAddress}
            gramId={gramId}
            gramMintPrice={gramMintPrice}
            isContractPrepared={isContractPrepared}
            isMintLoading={isMintLoading}
            isMintSuccess={isMintSuccess}
            mintTransData={mintTransData}
            mintTransError={mintTransError}
            isMintTransFetching={isMintTransFetching}
            isMintTransLoading={isMintTransLoading}
            isMintTransSuccess={isMintTransSuccess}
            mintSubmit={mintSubmit}
            mintTransactionHash={mintTransactionHash}
            handleBackToSubmitStep2={handleBackToSubmitStep2}
            handleSubmit={handleSubmitMint}
      />
    );

  }else if(isMintLinkKeyValid && isConnected && isReadyToStartMint && !isTweetIdSubmitted){
    content = (
      <CgMintStep2
            tweetId={tweetId}
            tweetWarningMsg={tweetWarningMsg}
            gramId={gramId}
            gramName={gramName}
            isTweetIdChecked={isTweetIdChecked}
            isTweetIdValid={isTweetIdValid}
            isTweetIdMissingInfo={isTweetIdMissingInfo}
            handleTweetChange={handleTweetChange}
            handleSubmit={handleSubmitStep2}
      />
    );

  }else if(isMintLinkKeyValid && (!isConnected || !isReadyToStartMint) ){
    content = (
      <CgMintStep0
            gramId={gramId}
            isConnected={isConnected}
            gramMintPrice={gramMintPrice}
            clickToTweetUrl={clickToTweetUrl}
            handleSubmit={handleSubmitStep0}
      />
    );
  }





  return (
    <div id="pageCandygramMint">
      <Navbar />
      {isMintLinkKeyValid && title}

      {isMintLinkKeyValid && warning}

      {content}

      <Divider />
      <Footer />
    </div>
  );
}
