import React, {useState,useEffect} from 'react'
import {useParams,useNavigate,useLoaderData} from "react-router-dom"

import { TwitterTweetEmbed } from 'react-twitter-embed';

import Navbar from './../navbar.jsx'
import Footer from './../footer.jsx'
import Divider from './../divider.jsx'

export default function CgNfc() {
  const navigate = useNavigate();
  const mintLinkData = useLoaderData();

  useEffect(() => {
    navigate(`/candygram/mintlink/${mintLinkData["link"]}`,{replace:true});
  },[]);

  if(("valid" in mintLinkData) && ("link" in mintLinkData) && mintLinkData["valid"] ){
    return <div className="loading loading-lg"></div>;
  }else{
    return (
      <div id="pageCandygramMint">
        <Navbar />
        <h1>CandyGram Link</h1>
        <h5 className="bg-error">
          This link is not longer valid and cannot be used to mint a CandyGram.  If you feel
          this is a mistake please contact <a href="https:twitter.com/CandyViz">@CandyViz</a>.
        </h5>

        <Divider />
        <Footer />
      </div>
    );
  }

}
