import React from 'react';
import { createRoot } from 'react-dom/client'
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

import {
  getDefaultWallets,
  RainbowKitProvider,
  lightTheme
} from '@rainbow-me/rainbowkit';

import {
  chain,
  configureChains,
  createClient,
  WagmiConfig,
} from 'wagmi';
import { alchemyProvider } from 'wagmi/providers/alchemy';
import { publicProvider } from 'wagmi/providers/public';
import { jsonRpcProvider } from '@wagmi/core/providers/jsonRpc'

const alchemyId = "JI4PUk482mU9gRWa59_obAUyyn4o_Nmr"; //process.env.ALCHEMY_ID;

const { chains, provider } = configureChains(
  [chain.mainnet],
  //[chain.mainnet, chain.goerli, chain.hardhat],
  //[chain.goerli, chain.hardhat],
  [
    alchemyProvider({ apiKey: alchemyId }),
    publicProvider(),
    jsonRpcProvider({
      rpc: (chain) => ({
        http: `http://localhost:8546`,
      }),
    }),
  ],
)
console.log("CHAINS",chains);

const { connectors } = getDefaultWallets({
  appName: 'DotMaps',
  chains
});

const client = createClient({
  autoConnect: true,
  connectors,
  provider
});

import Container from './components/container.jsx'
import Home from './components/home.jsx'
import Genesis from './components/genesis.jsx'
import IndividualGenesis from './components/individual-genesis.jsx'
import BgPatterns from './components/bg-patterns.jsx'
import CgIndex from './components/cg/index.jsx'
import CgMint from './components/cg/mint.jsx'
import CgMintTx from './components/cg/mint-tx.jsx'
import CgNfc from './components/cg/nfc.jsx'
import CgToken from './components/cg/token.jsx'
import CgPhysical from './components/cg/physical.jsx'
import CgSamples from './components/cg/samples.jsx'
import CgAnimated from './components/cg/animated.jsx'
import CgMintCoordinator from './components/cg/mint/coordinator.jsx'
import TwitterAuthRedirect from './components/twitter-auth-redirect.jsx'
import ErrorBoundary from './components/error-boundary.jsx'


const router = createBrowserRouter([
  {
    path: "/",
    element:  <Container layout="banner"><Home /></Container>,
    errorElement: <ErrorBoundary />,
  },
  {
    path: "/genesis",
    element: <Container layout="banner"><Genesis /></Container>,
    errorElement: <ErrorBoundary />,
  },
  {
    path: "/genesis/:token",
    element: <Container><IndividualGenesis /></Container>,
    errorElement: <ErrorBoundary />,
  },
  {
    path: "/patterns",
    element: <Container layout="banner"><BgPatterns /></Container>,
    errorElement: <ErrorBoundary />,
  },
  {
    path: "/candygram",
    element: <Container layout="banner"><CgIndex /></Container>,
    errorElement: <ErrorBoundary />,
  },
  {
    path: "/candygram/token/:tokenId",
    element: <Container><CgToken /></Container>,
    errorElement: <ErrorBoundary />,
  },
  {
    path: "/candygram/cg/:gramId",
    element: <Container><CgPhysical /></Container>,
    errorElement: <ErrorBoundary />,
  },
  {
    path: "/candygram/mint/:mintLinkKey",
    element: <Container><CgMint /></Container>,
    errorElement: <ErrorBoundary />,
  },
  {
    path: "/candygram/mintlink/:mintLinkKey",
    element: <Container><CgMintCoordinator /></Container>,
    errorElement: <ErrorBoundary />,
  },
  {
    path: "/candygram/mintlink/:mintLinkKey/auth",
    element: <Container><CgMintCoordinator auth="redirect"/></Container>,
    errorElement: <ErrorBoundary />,
  },
  {
    path: "/candygram/:gramId/tx/:transactionHash",
    element: <Container><CgMintTx /></Container>,
    errorElement: <ErrorBoundary />,
  },
  {
    path: "/candygram/samples",
    element: <Container><CgSamples /></Container>,
    errorElement: <ErrorBoundary />,
  },
  {
    path: "/candygram/animated/:tokenId/:tweetId/:imgData",
    element: <Container layout="full"><CgAnimated /></Container>,
    errorElement: <ErrorBoundary />,
  },
  {
    path: "/candygram/nfc/:nfc",
    element: <Container><CgNfc /></Container>,
    errorElement: <ErrorBoundary />,
    loader: async ({ params }) => {
      return fetch(`/api/candygram/generate-mint-link?nfc=${params.nfc}`);
    }
  },
  {
    path: "/twitter_auth_redirect",
    element: <Container><TwitterAuthRedirect /></Container>,
    errorElement: <ErrorBoundary />,
    loader: async ({ request }) => {
      const url = new URL(request.url);
      const oauthToken = url.searchParams.get('oauth_token')
      const oauthVerifier = url.searchParams.get('oauth_verifier')
      const linkKey = url.searchParams.get('linkKey')
      return fetch(`/api/twitter/access-token?linkKey=${linkKey}&oauth_token=${oauthToken}&oauth_verifier=${oauthVerifier}`);
    }
  },

]);


const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <React.StrictMode>
    <WagmiConfig client={client}>
      <RainbowKitProvider chains={chains} theme={lightTheme({borderRadius: 'none'})}>
        <RouterProvider router={router} />
      </RainbowKitProvider>
    </WagmiConfig>
  </React.StrictMode>
);

// <RainbowKitProvider chains={chains} theme={lightTheme({borderRadius: 'small',accentColor: 'black',overlayBlur:'none'})} >
