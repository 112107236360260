import React from 'react'

const imgPixelSizes = new URL('../images/dotmap-pixel-sizes.png', import.meta.url);

export default function AboutArt() {
  return (
    <div id="section-about-art">
      <h5>About the DotMap Art</h5>
      <p>
        What happens when you add limits to art? What happens when someone is told you can only use these two colors to paint a mural? What happens when you can only use the sand on a beach to express yourself? What happens when a kid can only tell a story by using shadows from a flash light long after their parents have put them to bed? What happens when a creative person is told they can only use a space 24x24 pixels to represent a human?  <em>The answer to all of these is that something completely unique has the potential to be created.</em>
      </p>
      <p>
        The DotMap project explores setting some of those limitations. Each DotMap in the collection is limited to having 1024 dots. Each individual dot is placed anywhere on a canvas of size 1024x1024, is assigned a radius of any size and a color using the RGB color format.  There are some exceptions as to how animation is done, some are done using <code>gif</code> other use <code>svg</code> transitions but both are based on multiple sets of DotMaps.  The design of DotMaps are kept as simple as possible so that they can be easily represented in both digital and physical worlds.
      </p>
      <figure className="figure" id="pixel-sizes">
        <img className="img-responsive" src={imgPixelSizes} alt="Pixel Art Sizes" />
      </figure>
      <p>
        How was 1024 chosen you ask?  Based on the current world of pixel art NFTs there are <a href="https://www.larvalabs.com/cryptopunks">CryptoPunks</a> and all their derivitives which are 24x24 pixels, <a href="https://opensea.io/collection/tiny-dinos-eth">tiny dinos</a> which are 16x16 pixels, <a href="https://nouns.wtf/">Nouns</a>(⌐◨-◨) & <a href="https://www.blitmap.com/">Blitmaps</a> which are 32x32 pixels. So the question became what would happen if all of those pixels were used in a different way? Using Nouns as a base: 32x32=1024 total pixels. From there what if you could move those pixels to any position on the canvas?  That would give you 1024 pixels to fill with any unique color you wanted and position any location you wanted. After a few attempts to use squares the softness of shapes wasn't quite coming across to then the shapes were changed to circles and a radius of the circle was added. So that means each DotMap is defined by 1024 6-tuples with integers that contain the data for <code>(X Coordinate, Y Coordinate, Radius, Red Color, Green Color, Blue Color)</code>. This means dots can overlap as well so the last constraint is that dots are drawn in the order they are listed with the first dots in the list being overlayed by the later dots. This makes DotMaps different than pixel art because there are layers that don't exist with pixelated art. With those constraints the DotMap project was born.
      </p>

      <p>
        Using the constraints above each DotMap visually begin their life as an SVG image with 1024 <code>&lt;circle&gt;</code> tags which is created and edited in order to best represent the essence of the subject of the piece.  Each DotMap is laid out on a 4096x4096 canvas.  The background of each can be a solid or gradient color pair but most are meant to be viewed on a white background or have a transparent background so they can be overlayed on to other physical things like shirts, prints and other wearables.
      </p>
      <p>
        Where did the name DotMaps come from? It is in reference to the NFT project Blitmaps that was created in May 2021 as an on-chain art project. When deciding on if the content should be for DotMaps inspiration was taken from the work of Blitmaps in that there was so much of everything. It wasn't just one type of layered pattern as so many NFT projects are now, it has emojis, words, abstract patterns, memes, and many other brilliant unique subjects. Every token felt truly unique.
      </p>
      <p>
        While DotMaps took visual inspiration Blitmaps and other Pixel art project on Ethereum, after exploring the possibilities and limitation of Ethereum on-chain art the size and data limitations were too extreme on Ethereum so the data for DotMaps is stored on Arweave which allowed for more potential cross-chain multi-chain possibilities in the future.
      </p>
      <p>
        There is no roadmap for what will be developed next or what is coming. We will have to all find out together, but this is just the beginning.  For more information follow @<a href="https://twitter.com/dotmapswtf">dotmapswtf</a> on twitter.
      </p>
    </div>
  );
}
