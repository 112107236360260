import React from 'react'

import Navbar from './navbar.jsx'
import Header from './header.jsx'
import Footer from './footer.jsx'
import Intro from './intro.jsx'
import AboutArt from './about-art.jsx'
import AboutCandy from './about-candy.jsx'
import Contracts from './contracts.jsx'



import Divider from './divider.jsx'

export default function() {
  return (
    <div id="pageHome">
      <Navbar />
      <Header />
      <Intro />
      <Divider />
      <Footer />
    </div>
  );
}

/*
<Divider />
<Contracts />
<Divider />
<AboutArt />
<Divider />
<AboutCandy />
*/
