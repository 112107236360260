import React from 'react'
import { Link } from "react-router-dom";

export default function SmallGenesisImage(props) {
  var imgThumb = '/data/'+props.token+'/'+props.token+'-thumb.png';
  var link = '/genesis/'+props.token';

  return (
    <div className="small-genesis-image col-3 col-xs-12 col-sm-6">

      <Link to={link}>
        <img className="img-responsive token" src={imgThumb} />
      </Link>
      <div className="token-label">#{props.token}</div>

    </div>
  );
}
