import React from 'react'


export default function CgMintSteps(props) {

  const steps = [
    {
      key: "scan",
      label:(<>Scan</>)
    },
    {
      key:"twitter",
      label:(<>Share</>)
    },
    {
      key:"connect",
      label:(<>Connect</>)
    },
    {
      key:"mint",
      label:(<>Mint</>)
    }
  ];

  let list = [];
  steps.forEach((v,k) => {
    let item = (<a>{v.label}</a>);
    let className = "step-item";
    if(v.key==props.step){
      className = "step-item active";
    }

    list.push(<li key={"step-key-"+k} className={className}>{item}</li>);
  });

  return (
    <ul className="step" style={{margin:"15px 0px"}}>
      {list}
    </ul>
  );
}
