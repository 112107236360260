import React, {useState,useEffect} from 'react'
import PropTypes from 'prop-types';

import { TwitterTweetEmbed } from 'react-twitter-embed'
import { useInterval } from 'usehooks-ts'

import { chain, useNetwork } from 'wagmi';



export default function CgMintSuccess(props) {
  const { chain } = useNetwork();
  return (
    <div>
      <div class="hero bg-gray">
        <div class="hero-body">
          <h1>You minted a token from a CandyGram!</h1>
          <p>Transaction <a target="_blank" href={`${chain.blockExplorers.default.url}/tx/${props.mintTransactionHash}`}>{props.mintTransactionHash}</a></p>
        </div>
      </div>

    </div>
  );

}

CgMintSuccess.propTypes = {

}
