import React from 'react'

import SmallGenesisImage from './small-genesis-image.jsx'

export default function SmallGenesisGallery() {
  const images = [];
  for (let i = 0; i < 64; i++) {
    const token = i+1
    const key = "small-gallery-key-"+token
    images.push(<SmallGenesisImage key={key} token={token} />);
  }
  return (
    <div id="section-small-genesis-gallery" className="columns">
      {images}
    </div>
  );
}
