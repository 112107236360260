import React, {useState,useEffect} from 'react'

import { useInterval } from 'usehooks-ts'

import CgMintWarning from './warning.jsx'
import CgMintError from './error.jsx'

export default function CgMintFrame(props) {
  const [isExpired, setIsExpired] = useState(false);

  function errorNum(msg){
    if(msg === null){
      return 0;
    }else if (typeof msg !== 'string'){
      return 0;
    }else{
      const re = /\(error (\d)\)/i;
      const found = msg.match(re);
      if(found==null){
        return 0;
      }else if(found.length==2){
        return parseInt(found[1]);
      }
    }
    return 0;
  }

  const errorNumber = errorNum(props.error);

  if(isExpired || errorNumber == 2){
    return (
      <CgMintError icon="⏰" message="Link Expired">
        This link has expired and can no longer be used. Please rescan the CandyGram to generate a new link.
      </CgMintError>
    );
  }else if(props.gramId == 0 || !props.isOnChainDataFetched){
    return (<div className="loading loading-lg"></div>);
  }else if(props.isOnChainDataFetched && !props.isGramMintable){
    return (
      <CgMintError icon="⏰" message="Gram is not Mintable">
        The Digital Token for this CandyGram is not yet mintable.
      </CgMintError>
    );
  }else if(errorNumber == 5){
    return (
      <CgMintError icon="⏰" message="Mint link already created">
        Only one mint link can be created every hour, either use the link you already created OR wait an hour and try again.
      </CgMintError>
    );
  }else if(props.error){
    return (
      <CgMintError icon="😢" message={props.error}>
        There are many reason an error may have occured when using this CandyGram mint link. Try generating the link again from the CandyGram you have.<br/><br/>
        Remember there is no rush as only the current chaperone of a CandyGram can mint at any given time.  <br/><br/>
        If the problem persists please contact <a href="https:/twitter.com/CandyViz">@CandyViz</a> on twitter.
      </CgMintError>
    );
  }else  {
    return (
      <div>
        <CgMintWarning expTime={props.expTime} gramId={props.gramId} onExpire={()=>setIsExpired(true)}/>

        <h2>CandyGram #{props.gramId}</h2>

        <>
          {props.children}
        </>
      </div>
    );
  }

}
