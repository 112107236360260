import React from 'react'


export default function CgMintError(props) {
  return (
    <div className="empty" style={{border:"1px solid #e85600"}}>
      <h3 className="empty-icon text-error">{props.icon}</h3>
      <p className="empty-title h5 text-error">{props.message}</p>
      <p className="empty-subtitle">
        {props.children}
      </p>
    </div>
  );
}
