import * as React from 'react';


export default function Container(props) {
  if(props.layout == "full"){
    return (
      <div className="container" style={{padding:"0",margin:"0"}}>
        {props.children}
      </div>
    );
  }else if(props.layout == "banner"){
    return (
      <>
        <div className="bg-primary" style={{display:"none"}}>
          <div className="container grid-lg" style={{textAlign:"center",padding:"5px"}}>
            25% of all withdrawals from the <a style={{color:"#fff"}} href="https://etherscan.io/address/0xaa1b34f88e394decc41e58c9038380236fecb006">DotMaps Deployer</a> wallet will be donated to the <a style={{color:"#fff"}} href="https://www.michaeljfox.org/">Michael J. Fox Foundation</a>.
          </div>
        </div>
        <div className="container grid-lg">
          {props.children}
        </div>
      </>
    );
  }else{
    return (
      <div className="container grid-lg">
        {props.children}
      </div>
    );
  }

}
