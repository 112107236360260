import React from 'react'


const _shirts = new URL('../images/physical/shirts-home.png', import.meta.url);
const _hats = new URL('../images/physical/hats-home.png', import.meta.url);
const _hoodies = new URL('../images/physical/hoodies-home.png', import.meta.url);

export default function PhysicalGenesisGallery() {

  return (
    <div id="section-physical-genesis-gallery" className="columns text-center mt-4 mb-4">
      <div className="col-4 col-sm-12 p-2">
        <a href="https://physical.dotmaps.wtf/collections/t-shirts">
          <img className="img-responsive" src={_shirts} />
        </a>
        <h5>
          <a href="https://physical.dotmaps.wtf/collections/t-shirts">Shirts</a>
        </h5>
      </div>
      <div className="col-4 col-sm-12 p-2">
        <a href="https://physical.dotmaps.wtf/collections/hats">
          <img className="img-responsive" src={_hats} />
        </a>
        <h5>
          <a href="https://physical.dotmaps.wtf/collections/hats">Hats</a>
        </h5>
      </div>
      <div className="col-4 col-sm-12 p-2">
        <a href="https://physical.dotmaps.wtf/collections/hoodies">
          <img className="img-responsive" src={_hoodies} />
        </a>
        <h5>
          <a href="https://physical.dotmaps.wtf/collections/hoodies">Hoodies</a>
        </h5>
      </div>
    </div>
  );
}
