import * as React from 'react';
import { Routes, Route, useParams, Link } from 'react-router-dom';

import Navbar from './navbar.jsx'
import Footer from './footer.jsx'
import SmallGenesisGallery from './small-genesis-gallery.jsx'
import Divider from './divider.jsx'
import TokenDetails from './token-details.jsx'

const logoOS = new URL('../images/opensea-logo.png', import.meta.url);
const logoEtherscan = new URL('../images/etherscan-logo.png', import.meta.url);
const logoLooksrare = new URL('../images/looksrare-logo.png', import.meta.url);


export default function IndividualGenesis() {
  let params = useParams();
  var imgOpaque = '/data/'+params.token+'/'+params.token+'-opaque.png';
  var imgTransparent = '/data/'+params.token+'/'+params.token+'-transparent.png';
  var imgVectorOpaque = '/data/'+params.token+'/'+params.token+'-opaque.svg';
  var imgVectorTransparent = '/data/'+params.token+'/'+params.token+'-transparent.svg';
  var dataJson = '/data/'+params.token+'/'+params.token+'-data.json';
  var linkOpensea = 'https://opensea.io/assets/ethereum/0x7f99a36556a05c80e2c30d8663a7ff6ec69022cb/'+params.token';
  var linkLooksrare = 'https://looksrare.org/collections/0x7f99A36556a05C80E2C30D8663a7Ff6eC69022cB/'+params.token';
  var linkEtherscan = 'https://etherscan.io/token/0x7f99a36556a05c80e2c30d8663a7ff6ec69022cb?a='+params.token';

  let prevLink, nextLink;
  if (params.token > 1) {
    const prevToken = parseInt(params.token)-1
    const url = "/genesis/"+prevToken
    prevLink = <Link to={url} className="btn btn-link"><i className="icon icon-arrow-left"></i></Link>;
  }
  if (params.token < 64) {
    const nextToken = parseInt(params.token)+1
    const url = "/genesis/"+nextToken
    nextLink = <Link to={url} className="btn btn-link"><i className="icon icon-arrow-right"></i></Link>;
  }

  return (
    <div id="page-individual-genesis" className="individual-genesis">
      <Navbar />
      <div className="columns token-header">
        <div className="col-1 scroll-links hide-sm">
          {prevLink}
        </div>
        <div className="col-10 col-sm-12">
          <h1>DotMap Genesis #{params.token}</h1>
        </div>
        <div className="col-1 scroll-links hide-sm">
          {nextLink}
        </div>
      </div>
      <div className="columns token-header">
        <div className="col-6 scroll-links show-sm">
          {prevLink}
        </div>
        <div className="col-6 scroll-links show-sm">
          {nextLink}
        </div>
      </div>
      <div className="token-image">
        <img className="img-responsive token" src={imgOpaque} />
      </div>

      <div className="columns">
        <div className="col-6 col-sm-12">
          <TokenDetails token={params.token} collection="genesis"/>
        </div>

        <div className="col-6 col-sm-12 logo-links">
          <a href={linkEtherscan}>
            <img src={logoEtherscan} />
          </a>
          <a href={linkOpensea}>
            <img src={logoOS} />
          </a>
          <a href={linkLooksrare}>
            <img src={logoLooksrare} />
          </a>
        </div>
      </div>

      <div className="columns all-token-images">
        <div className="col-6 filetype">
          Raster (.png)
        </div>
        <div className="col-6 filetype">
          Vector (.svg)
        </div>
        <div className="col-12 gallery">
          <div className="columns">
            <div className="col-3">
              <a href={imgOpaque}>
                <img className="img-responsive token" src={imgOpaque} />
              </a>
              <div>Opaque</div>
            </div>
            <div className="col-3">
              <a href={imgTransparent}>
                <img className="img-responsive token-transparent" src={imgTransparent} />
              </a>
              <div>Transparent</div>
            </div>
            <div className="col-3">
              <a href={imgVectorOpaque}>
                <img className="img-responsive token" src={imgVectorOpaque} />
              </a>
              <div>Opaque</div>
            </div>
            <div className="col-3">
              <a href={imgVectorTransparent}>
                <img className="img-responsive token-transparent" src={imgVectorTransparent} />
              </a>
              <div>Transparent</div>
            </div>
          </div>
        </div>
      </div>

      <Divider />

      <h5>DotMap #{params.token} Data</h5>

      <p>
        Each DotMap consists of 1024 circles each one has 6 properties that define its characteristics.<br/>
        <code>(X Coordinate, Y Coordinate, Radius, Red Color, Green Color, Blue Color)</code><br/>
        Copy the data below OR open the <a href={dataJson}>data.json</a>.
      </p>

      <iframe src={dataJson}></iframe>

      <Divider />
      <Footer />

    </div>
  );
}
