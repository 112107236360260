import React from 'react'


export default function CgMintTweetList(props) {
  let media = {};
  props.list?.includes?.media.forEach((v,k) => {
    media[v.media_key]=v.url;
  })


  let list = [];
  props.list?.data.forEach((v,k) => {
    let buttonClass = "btn btn-sm "
    if(v.id==props.tweetId){
      buttonClass += " btn-success"
    }else if(v.id==props.selectedTweetId){
      buttonClass += " loading"
    }else{
      buttonClass += " btn-primary"
    }

    let imgUrl = "";
    if(v?.attachments?.media_keys[0]){
      imgUrl = media[v?.attachments?.media_keys[0]];
    }

    list.push(
      <div className="column col-4 col-md-6 col-sm-12">
        <div className="card">
          <div className="card-body">
            {v.text}
          </div>
          {imgUrl &&
            <div className="card-image" style={{padding:"15px"}}>
              <img src={imgUrl} className="img-responsive" />
            </div>
          }

          <div className="card-footer">
            {v.id != props.tweetId && <button className={buttonClass} onClick={(e)=>{props.selectTweet(v.id)}}>Select</button>}
            {v.id == props.tweetId && <button className={buttonClass} onClick={(e)=>{props.selectTweet(0)}}><i className="icon icon-check"></i></button>}
          </div>
        </div>
      </div>
    );

  });

  return (

    <div>
      <p>
        Below is a list of tweets you have sent that match the crieteria to mint.  Please select the one you would like to associate with this digital token.
      </p>
      <div className="columns">
        {list}
      </div>
    </div>
  );
}
