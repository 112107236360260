import React, {useState,useEffect} from 'react'

import {useParams,useNavigate,useLoaderData,useSearchParams,Link} from "react-router-dom"


export default function TwitterAuthRedirect() {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [accessTokens, setAccessTokens] = useState({});
  const loaderData = useLoaderData();
  // linkKey=ffdsafdsa&oauth_token=cdKXvgAAAAABiGddAAABhSimgaQ&oauth_verifier=6nLZE9wkGRsVKAv2PWrVoIR6QNeg5sVt

  const linkKey = searchParams.get('linkKey')
  const oauthToken = searchParams.get('oauth_token')
  const oauthVerifier = searchParams.get('oauth_verifier')


  const url = `/candygram/mintlink/${linkKey}/auth`
  useEffect(() => {
    navigate(url,{replace:true});
  },[]);


  return (
    <div>
      <p>Access Token</p>
      <Link to={url}>back to mint page</Link>
    </div>
  );
}
