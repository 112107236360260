import React, { useState, useEffect } from 'react'
import { useParams, useNavigate, Link } from "react-router-dom"

import { TwitterTweetEmbed } from 'react-twitter-embed'
import { useInterval } from 'usehooks-ts'

import { useAccount,
         useSignMessage,
         usePrepareContractWrite,
         useContractWrite,
         useWaitForTransaction,
         useContractRead
} from 'wagmi'
import { parseEther, formatEther } from "@ethersproject/units";

import { useDmContract } from './../../../hooks/dm-contract.js'
import { useGramData,useIsGramMintable } from './../../../hooks/gram-data.js'

import Navbar from './../../navbar.jsx'
import Footer from './../../footer.jsx'
import Divider from './../../divider.jsx'

import CgMintSteps from './steps.jsx'
import CgMintFrame from './frame.jsx'
import CgMintIntro from './intro.jsx'
import CgMintSendTweet from './send-tweet.jsx'
import CgMintTweetList from './tweet-list.jsx'
import CgMintPreviewTweet from './preview-tweet.jsx'
import CgMintAuthTwitter from './auth-twitter.jsx'
import CgMintConnectWallet from './connect-wallet.jsx'
import CgMintDestinationWallet from './destination-wallet.jsx'
import CgMintPrepTransaction from './prep-transaction.jsx'






export default function CgMintCoordinator(props) {
  const navigate = useNavigate();
  const params = useParams();
  const linkKey = params.mintLinkKey;

  useEffect(() => {
    if(props?.auth=="redirect"){
      setHighestStage(1);
      setSelectedStage(1);
      setAuthWait(true);
      navigate(`/candygram/mintlink/${params.mintLinkKey}`);
    }
  }, []);


  const [highestStage, setHighestStage] = useState(0);
  const [selectedStage, setSelectedStage] = useState(0);

  const [reqId, setReqId] = useState("");
  const [reqStale, setReqStale] = useState(true);

  const [majorError, setMajorError] = useState("");

  const [gramId, setGramId] = useState(0);
  const [gramName, setGramName] = useState("");
  const [expTime, setExpTime] = useState(0);

  const [selectedTweetId,setSelectedTweetId] = useState(0);
  const [tweetList, setTweetList] = useState([]);
  const [twitterUserId, setTwitterUserId] = useState(0);
  const [tweetId,setTweetId] = useState(0);
  const [authWait,setAuthWait] = useState(false);

  const [mintAttempt, setMintAttempt] = useState(0);
  const [sig, setSig] = useState({});

  const [tx, setTx] = useState("");

  const { address, isConnected } = useAccount();

  function requestMintData(lk,st,a,ma){
    const qs = `linkKey=${linkKey}&tweetId=${selectedTweetId}&address=${address}&attempt=${mintAttempt}`
    fetch(`/api/candygram/prepare-mint?${qs}`)
    .then(response => response.json())
    .then((data) => {
      console.log(data);
      setReqId(data.request);
      setReqStale(false);
      if("error" in data){
        setMajorError(data.error);
      }else{
        setGramId(data.gramId);
        setGramName(data.gram.name);
        setExpTime(data.expTime);

        if(data?.auth?.valid){ setAuthWait(false); }
        setTwitterUserId(data?.auth?.valid?data.auth.userId:0);

        if(data?.tweets){ setTweetList(data.tweets); }
        if(data?.tweet?.valid){
          setTweetId(data.tweetId);
        }else{
          setTweetId(0);
          setSelectedTweetId(0);
        }

        if(data?.sig){ setSig(data.sig); }
        if(data?.attempt){ setMintAttempt(parseInt(data.attempt)); }
      }
    });
  }

  useEffect(() => {
    requestMintData(linkKey,selectedTweetId,address,mintAttempt);
  },[linkKey,selectedTweetId,address,mintAttempt,selectedStage])

  useEffect(() => {

  },[tx])

  function selectTweet(tweetId){
    setSelectedTweetId(tweetId);
  }

  function logoutTwitter(){
    console.log("logout");
    setSelectedTweetId(1); // setting tweetId to 1 will log out the user
  }

  function incMintAttempts(){
    console.log("inc mint attempts",mintAttempt);
    setMintAttempt(parseInt(mintAttempt)+1);
  }

  const {
    isGramDataFetched,
    isGramOnChain,
    gramCurrentToken,
  } = useGramData(gramId);

  const {
    isGramMintable,
    isGramMintableError,
    isGramMintableFetched,
  } = useIsGramMintable(gramId);




  const stages = [
    {
      activeStep: "scan",
      title:"Congratulations! You scanned a CandyGram 🥳",
      subtitle:"This is an experimental NFT where community IS the art",
      content: (<CgMintIntro linkKey={linkKey} reqId={reqId} gramId={gramId} gramName={gramName}/>),
      isNextEnabled: true,
      buttonText:"Get Started"
    },
    {
      activeStep: "twitter",
      title:"Share on Twitter",
      subtitle: "Login to your Twitter Account",
      content: (<CgMintAuthTwitter linkKey={linkKey}
                                    authWait={authWait}
                                    triggerDataRefresh={triggerDataRefresh}
                                    reqId={reqId}
                                    tweetId={tweetId}
                                    twitterUserId={twitterUserId}
                                    logout={logoutTwitter}/>),
      isNextEnabled: !!twitterUserId
    },
    {
      activeStep: "twitter",
      title:"Share on Twitter",
      subtitle: `Take a photo with ${gramName}`,
      content: (<CgMintSendTweet gramName={gramName} />),
      isNextEnabled: true
    },
    {
      activeStep: "twitter",
      title:"Share on Twitter",
      subtitle: "Select the tweet below",
      content: (<CgMintTweetList reqId={reqId}
                                 gramName={gramName}
                                 list={tweetList}
                                 selectedTweetId={selectedTweetId}
                                 tweetId={tweetId}
                                 selectTweet={selectTweet}/>),
      isNextEnabled: !!tweetId
    },
    // {
    //   activeStep: "twitter",
    //   title:"Share on Twitter",
    //   subtitle: "Preview tweet",
    //   content: (<CgMintPreviewTweet tweetId={tweetId} />),
    //   isNextEnabled: true
    // },
    {
      activeStep: "connect",
      title:"Connect Wallet",
      subtitle: "Securely connect your eth payment wallet",
      content: (<CgMintConnectWallet reqId={reqId} isConnected={isConnected} address={address}/>),
      isNextEnabled: isConnected
    },
    // {
    //   activeStep: "connect",
    //   title:"Connect Wallet",
    //   subtitle: "Where would you like to send the digital token?",
    //   content: (<CgMintDestinationWallet isConnected={isConnected} address={address}/>),
    //   isNextEnabled: true
    // },
    {
      activeStep: "mint",
      title:"Mint your digital token",
      subtitle: "mint mint LFG",
      content: (<CgMintPrepTransaction reqId={reqId}
                                       sig={sig}
                                       mintAttempt={mintAttempt}
                                       setTx={setTx} />),
      isNextEnabled: true,
      buttonText: "Mint digital token",
      buttonAction: incMintAttempts
    },
    {
      activeStep: "mint",
      title:"Mint your digital token",
      subtitle: "Transaction pending",
      content: (<div class="loading"></div>),
      isNextEnabled: true
    },
    {
      activeStep: "mint",
      title:"Mint your digital token",
      subtitle: "mint complete",
      content: (<div>Party</div>),
      isNextEnabled: true
    }
  ];

  function nextStage(){
    const nextStage = selectedStage + 1;
    setSelectedStage(nextStage)
    if(highestStage<nextStage){
      setHighestStage(nextStage)
    }
    setReqStale(true);
  }

  function prevStage(){
    const prevStage = selectedStage - 1;
    setSelectedStage(prevStage);
    setReqStale(true);
  }

  function triggerDataRefresh(){
    setReqStale(true);
  }

  const step = stages[highestStage].activeStep;
  const stage = stages[selectedStage];
  const lastStage = stages.length-1;
  return (
    <div id="pageMintCoordinator">
      <Navbar />

      <CgMintFrame reqStale={reqStale}
                   gramId={gramId}
                   expTime={expTime}
                   error={majorError}
                   isOnChainDataFetched={isGramDataFetched && isGramMintableFetched}
                   isGramMintable={isGramMintable}>

        <CgMintSteps step={step} />

        <h3 className="text-primary" style={{padding:"0 10px 0 10px"}}>{stage.title}</h3>

        <div style={{padding:"0 10px 10px 10px",borderBottom:"1px solid #d9d9d9",borderLeft:"1px solid #d9d9d9",borderRight:"1px solid #d9d9d9"}}>

          <h5 className="text-gray" style={{marginBottom:"20px"}}>{stage.subtitle}</h5>

          {reqStale && <div className="loading loading-lg"></div>}
          {!reqStale &&
            <>
              <div style={{minHeight:"200px"}}>
                {stage.content}
              </div>

              <div style={{textAlign:"right",borderTop:"1px solid #d9d9d9",paddingTop:"10px",marginTop:"20px"}}>

                <div className="columns">
                  <div className="column col-6" style={{textAlign:"left"}}>
                    {selectedStage > 0 &&
                      <a className="btn btn-link btn-secondary" onClick={prevStage}><i className="icon icon-arrow-left"></i> go back</a>
                    }
                  </div>
                  <div className="column col-6" style={{textAlign:"right"}}>
                    {selectedStage != lastStage &&
                      <button className="btn btn-lg btn-success" onClick={stage?.buttonAction ? stage?.buttonAction : nextStage} disabled={!stage?.isNextEnabled}>
                        {stage?.buttonText ? stage?.buttonText : "Next"} <i className="icon icon-arrow-right"></i>
                      </button>
                    }
                  </div>
                </div>
              </div>
            </>
          }
        </div>

      </CgMintFrame>
      <div>
        RequestID:{reqId}<br/>
        MintAttempt:{mintAttempt}<br/>
        TwitterUserId:{twitterUserId}<br/>
        TweetId:{tweetId}<br/>
        Sig:{sig.signature}
      </div>

      <Divider />
      <Footer />
    </div>

  );


}
