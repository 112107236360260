import React, {useState,useEffect} from 'react'
import {useParams,useNavigate, Link} from "react-router-dom"

import { useContractRead } from 'wagmi'
import { TwitterTweetEmbed } from 'react-twitter-embed'

import { parseEther,formatEther } from "@ethersproject/units";

import { useDmContract } from './../../hooks/dm-contract.js'
import { useGramTokenData, useGramData } from './../../hooks/gram-data.js'

import Navbar from './../navbar.jsx'
import Footer from './../footer.jsx'
import Divider from './../divider.jsx'
import Parallax from './../parallax.jsx'
import TokenDetails from './../token-details.jsx'

import CgSampleGallery from './sample-gallery.jsx'

import cgData from "./candygram-data.json";

export default function CgToken() {
  let params = useParams();
  const [tokenId, setTokenId] = useState(params.tokenId);

  const {
    tokenUriError,
    isTokenUriError,
    isTokenUriFetched,
    tokenName,
    tokenDescription,
    tokenTweetId,
    tokenImageUrl,
    gramId,
    tokenFgImageName,
    tokenBgImageName,
    tokenRotation,
    tokenColorR,
    tokenColorG,
    tokenColorB,
    tokenAdjB,
    tokenAdjC,
    tokenAdjG,
    tokenTilesPerSide,
    isTokenRevealed,

    totalTokens,

    tokenMintBlock,
    tokenLastTransferBlock,
    tokenRenderBlock,
    tokenPreviousToken,

    //gramTotalTokens, setTotalTokens] = useState(0);
  } = useGramTokenData(tokenId);

  const rgb = `rgb(${tokenColorR},${tokenColorG},${tokenColorB})`;

  const {
    gramName,
    isGramDataFetched,
    isGramFound,
    isGramPublished,
    gramDescription,
    gramFrontImage,
    gramBackImage,
    gramOnChainDataError,
    isGramOnChainDataError,
    isGramOnChainDataFetched,
    isGramOnChain,
    gramCurrentToken,
    gramTokenLimit,
    gramTokenCount,
    gramMintPrice,
    gramFreeMintCount,
    gramStartBlock,
    gramStopBlock,
  } = useGramData(gramId);


  const lastSlash = tokenImageUrl.lastIndexOf('/');
  const bgImageUrl = tokenImageUrl.substring(0, lastSlash) + "/bg-only-" + tokenImageUrl.substring(lastSlash + 1);
  const fgImageUrl = tokenImageUrl.substring(0, lastSlash) + "/fg-only-" + tokenImageUrl.substring(lastSlash + 1);;

  let content = <div className="loading loading-lg"></div>;
  if( isTokenUriError ){
    console.log("ERROR tokenURI()",tokenUriError);
  }

  let prevLink="";
  let nextLink="";
  if (tokenId > 1) {
    const prevToken = parseInt(tokenId)-1;
    const url = "/candygram/token/"+prevToken;
    prevLink = <a href={url} className="btn btn-link"><i className="icon icon-arrow-left"></i></a>;
  }
  if (tokenId < totalTokens) {
    const nextToken = parseInt(tokenId)+1;
    const url = "/candygram/token/"+nextToken;
    nextLink = <a href={url} className="btn btn-link"><i className="icon icon-arrow-right"></i></a>;
  }

  let imageDetails = (
    <div>
      <h5 className="bg-warning" style={{textAlign:"center",padding:"5px"}}>Image not revealed 🤷‍♀️</h5>
      <p>In order for this token to reveal the next token from this CandyGram must be minted.</p>
    </div>
  );
  if(isTokenRevealed){
    imageDetails = (
      <table width="100%">
        <tbody>
          <tr>
            <td colSpan="3" className="bg-secondary" style={{textAlign:"center"}}>Foreground</td>
          </tr>
          <tr>
            <td width="25%" style={{padding:"1px"}}>Image</td>
            <td width="25%" style={{padding:"1px"}}>{tokenFgImageName}</td>
            <td width="50%" style={{padding:"1px"}}>Set at reveal</td>
          </tr>
          <tr>
            <td style={{padding:"1px"}}>Rotation</td>
            <td style={{padding:"1px"}}>{tokenRotation}°</td>
            <td style={{padding:"1px"}}>Set at reveal</td>
          </tr>
          <tr>
            <td style={{padding:"1px"}}>Brightness</td>
            <td style={{padding:"1px"}}>
              <div>{tokenAdjB} of 16</div>
              <div style={{lineHeight:"1px"}}><progress className="progress" value={tokenAdjB} max="16"></progress></div>
            </td>
            <td style={{padding:"1px"}}>Set at reveal</td>
          </tr>
          <tr>
            <td style={{padding:"1px"}}>Contrast</td>
            <td style={{padding:"1px"}}>
              <div>{tokenAdjC} of 16</div>
              <div style={{lineHeight:"1px"}}><progress className="progress" value={tokenAdjC} max="16"></progress></div>
            </td>
            <td style={{padding:"1px"}}>Set at reveal</td>
          </tr>
          <tr>
            <td style={{padding:"1px"}}>Gamma</td>
            <td style={{padding:"1px"}}>
              <div>{tokenAdjG} of 16</div>
              <div style={{lineHeight:"1px"}}><progress className="progress" value={tokenAdjG} max="16"></progress></div>
            </td>
            <td style={{padding:"1px"}}>Set at reveal</td>
          </tr>
          <tr>
            <td colSpan="3" className="bg-secondary" style={{textAlign:"center"}}>Background</td>
          </tr>
          <tr>
            <td width="25%" style={{padding:"1px"}}>Pattern</td>
            <td width="25%" style={{padding:"1px"}}>{tokenBgImageName}</td>
            <td width="50%" style={{padding:"1px"}}>Set at reveal</td>
          </tr>
          <tr>
            <td style={{padding:"1px"}}>Color</td>
            <td style={{padding:"1px"}}>
              <div>{rgb}</div>
              <div style={{height:"15px",backgroundColor:rgb}}></div>
            </td>
            <td style={{padding:"1px"}}>Changes daily, the availible pallete increase the longer the token is held in a wallet</td>
          </tr>
          <tr>
            <td style={{padding:"1px"}}>Tiling</td>
            <td style={{padding:"1px"}}>{tokenTilesPerSide}x{tokenTilesPerSide}</td>
            <td style={{padding:"1px"}}>Decreases the more tokens a wallet holds</td>
          </tr>
        </tbody>
      </table>
    );
  }



  return (
    <div>
      <Navbar />

      <div className="columns token-header">
        <div className="col-1 scroll-links hide-sm">
          {prevLink}
        </div>
        <div className="col-10 col-sm-12">
          <h1><Link to="/candygram">CandyGram</Link>: Token #{tokenId}</h1>
        </div>
        <div className="col-1 scroll-links hide-sm">
          {nextLink}
        </div>
      </div>
      <div className="columns token-header">
        <div className="col-6 scroll-links show-sm">
          {prevLink}
        </div>
        <div className="col-6 scroll-links show-sm">
          {nextLink}
        </div>
      </div>

      <div className="columns">
        <div className="col-12" style={{textAlign:"center"}}>
          <span className="text-primary">{tokenDescription}</span>
        </div>
        <div className="col-6 col-sm-12" style={{padding:"10px"}}>
          <h3 className="bg-dark" style={{padding:"5px",textAlign:"center"}}>{gramName}</h3>
          <TokenDetails token={tokenId} collection="candygram"/>
          <div style={{padding:"10px"}}>
            <img src={tokenImageUrl} className="img-responsive token" />
          </div>

          <div>{imageDetails}</div>

        </div>
        <div className="col-6 col-sm-12" style={{padding:"10px"}}>
          {tokenTweetId &&
            <div>
              <h3 className="bg-dark" style={{padding:"5px",textAlign:"center"}}>Minting Tweet 🚀</h3>
              <TwitterTweetEmbed tweetId={tokenTweetId} placeholder="Tweet Loading..." />
            </div>
          }
        </div>
      </div>

      { isTokenRevealed &&
        <div className="columns">
          <div className="col-6 col-sm-12" style={{padding:"10px"}}>
            <h3 className="bg-dark" style={{padding:"5px",textAlign:"center"}}>Foreground</h3>
            <div style={{padding:"10px"}}>
              <img src={fgImageUrl} className="img-responsive" />
            </div>
          </div>
          <div className="col-6 col-sm-12" style={{padding:"10px"}}>
            <h3 className="bg-dark" style={{padding:"5px",textAlign:"center"}}>Background</h3>
            <div style={{padding:"10px"}}>
              <img src={bgImageUrl} className="img-responsive token" />
            </div>
          </div>
        </div>
      }


      <div className="columns">
        <div className="col-12" style={{textAlign:"center"}}>
          <h3 className="bg-dark" style={{padding:"5px",textAlign:"center"}}>About CandyGram #{gramId} ({gramName})</h3>
        </div>
        <div className="col-3 col-sm-6" style={{padding:"10px"}}>
          <Parallax img={gramFrontImage} caption="Front"/>
        </div>
        <div className="col-3 col-sm-6" style={{padding:"10px"}}>
          <Parallax img={gramBackImage} caption="Back"/>
        </div>
        <div className="col-6 col-sm-12" style={{padding:"10px"}}>
          <p dangerouslySetInnerHTML={{ __html:gramDescription}} />
          <div>
            <Link to={`/candygram/cg/${gramId}`}>more details</Link>
          </div>
        </div>
      </div>


      <Divider />
      <Footer />
    </div>
  );
}
