import React from 'react'

const imgOCM = new URL('../images/ocm7403.png', import.meta.url);
const imgAF = new URL('../images/af3408.png', import.meta.url);
const imgTD = new URL('../images/td918.png', import.meta.url);
const imgCM = new URL('../images/cm492.png', import.meta.url);
const imgLP = new URL('../images/lp64.png', import.meta.url);
const imgMA = new URL('../images/ma47.png', import.meta.url);

export default function AboutCandy() {
  return (
    <div id="section-about-candy">
      <h5>About the Artist C🅰️ndyViz</h5>
      <p>
        First off I am a creator and a collector.  In online circles I now go by the pseudonym CandyViz.  Professionally I am a software engineer.  I have been working in web2 startups since the early days of the internet.  I cut my teeth early days with e-commerce when <a href="https://en.wikipedia.org/wiki/Common_Gateway_Interface">cgi</a> and <a href="https://www.perl.org/">perl</a> were still a thing and was early to the Javascript <a href="https://en.wikipedia.org/wiki/Web_2.0">Web 2.0</a> revolution.  I have overtime evolved to mobile engineering as well as the internet changed to mobile first.  All this time I have been painting, drawing and pursuing artistic endevors as a way to relax the genes parents passed down to me.  When I first came to web3 I was blown away with the ability to combine the two passions in my life.  <em>Art & Technology</em>.
      </p>

      <p>
        The pseudonym CandyViz comes from a very colorful but now defunct javascript data visualization project I created that built charts and graphs using candy. For more information follow @<a href="https://twitter.com/CandyViz">C🅰️ndyViz</a> on twitter.  You may find me using one of the avatars below from some amazing artists and NFT communities.
      </p>

      <div className="container">
        <div className="columns">
          <div className="column col-4">
            <img className="img-responsive" src={imgOCM} alt="OCM" />
          </div>
          <div className="column col-4">
            <img className="img-responsive" src={imgAF} alt="AF" />
          </div>
          <div className="column col-4">
            <div className="columns" id="tiny-avatars">
              <div className="column col-6">
                <img className="img-responsive" src={imgTD} alt="TD" />
              </div>
              <div className="column col-6">
                <img className="img-responsive" src={imgCM} alt="MofC" />
              </div>
              <div className="column col-6">
                <img className="img-responsive" src={imgLP} alt="lp" />
              </div>
              <div className="column col-6">
                <img className="img-responsive" src={imgMA} alt="ma" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <ul>
        <li><a href="https://onchainmonkey.com/">On-chain Monkey</a> - an amazing community and project, all about bringing goodness to the world.</li>
        <li><a href="https://www.alienfrens.io/">Alien Frens</a> - an amazing community and project supporting artists and creators.</li>
        <li><a href="https://opensea.io/collection/tiny-dinos-eth">Tiny Dinos</a> - CC0, Cross-chain, cute AF, active community and development.  What more could you ask?</li>
        <li><a href="https://monksofcrypto.com/">Monks of Crypto</a> - Amazing solo creator building 3d models by hand for each monk that will work in what ever the metaverse becomes.</li>
        <li><a href="https://opensea.io/collection/littlepills">Little Pills</a> - Amazing artist <a href="https://twitter.com/mintmaker">@MintMake</a> doing amazing 3D art and bringing energy of acceptance in to the world.  Thank you for all your support.</li>
        <li><a href="https://opensea.io/collection/melancholy-androids">Melancholy Androids</a> - By the artist <a href="https://twitter.com/OGRE_3D">@OGRE_3D</a> who is going to invite the whole world in to his cave eventually to see him create dark beauty.</li>
      </ul>
    </div>
  );
}
