import React from 'react'

import Navbar from './navbar.jsx'
import Footer from './footer.jsx'
import SmallGenesisGallery from './small-genesis-gallery.jsx'
import Divider from './divider.jsx'

export default function Genesis() {
  return (
    <div id="pageGenesis">
      <Navbar />
      <h1>Genesis Collection</h1>
      <p>
        This is the collection that started the DotMap journey.  It contains 64 tokens at the monent with plans to potentially expand with different styles but still cohesively tied together as the genesis collection.  The collection total size will be determined by the market but the each will continue to be unique.
      </p>

      <SmallGenesisGallery />
      <Divider />
      <Footer />
    </div>
  );
}
