import React, {useState,useEffect} from 'react'
import PropTypes from 'prop-types';

import { TwitterTweetEmbed } from 'react-twitter-embed'
import { useInterval } from 'usehooks-ts'



function CgMintStep2(props) {
  let tweetIdClass = "bg-primary";
  if(props.isTweetIdChecked && !props.isTweetIdValid){
    tweetIdClass = "bg-error";
  }else if(props.isTweetIdChecked && props.isTweetIdValid){
    tweetIdClass = "bg-success";
  }

  const [potentialTweets,setPotentialTweets] = useState([]);
  const [tweetId,setTweetId] = useState(props.tweetId);

  useEffect(() => {
    const qs = `name=${props.gramName}`
    fetch(`/api/candygram/twitter-search?${qs}`)
    .then(response => response.json())
    .then((data) => {

      if(("valid" in data) && data.valid){
        console.log(data.tweets.data);
        setPotentialTweets(data.tweets.data);
      }
    })
  },[])

  function handleTweetClaim(id){
    setTweetId(id);
    props.handleTweetChange(id);
  }

  const tweetList = [];
  if(tweetId.length==0){
    potentialTweets.data?.forEach((d,k) => {
      const key = `${k}-tweet-list`;
      tweetList.push(
        <div key={key} className="bg-gray" style={{margin:"3px 0px 3px 20px"}}>
          <div className="columns">
            <div className="column col-1 col-sm-3"><button className="btn" onClick={()=>{handleTweetClaim(d.id)}}>Claim</button></div>
            <div className="column col-11 col-sm-9">{d.text}</div>
          </div>

        </div>
      )
    });
  }

  return (
    <div>
      <h4>Step 2</h4>

      <p>
        In order to mint the digital CandyGram #{props.gramId} you need to tweet a picture of the physical
        CandyGram (try to be as creative as possible) and include the hashtag <code>#candygramnft</code> and
        tag <code>@dotmapswtf</code>.  Paste the link to the tweet below so it can be verified.
      </p>

      {tweetList.length>0 &&
        <div id="recentTweetList" style={{margin:"10px 0px"}}>
          <div><h6>Select your tweet about {props.gramName} below</h6></div>
          {tweetList}

          <h5>OR copy in the tweet ID or URL below.</h5>
        </div>
      }

      <div id="tweetIdForm">
        <div className={tweetIdClass}>
          <input type="text" className="form-input" value={tweetId} placeholder="Tweet URL or ID" onChange={(event)=>props.handleTweetChange(event.target.value)}/>
        </div>
        {props.isTweetIdValid &&
          <div>
            <button className="btn btn-primary" onClick={props.handleSubmit}>Next <i className="icon icon-arrow-right"></i></button>
          </div>
        }
      </div>



      <div>
        {props.isTweetIdChecked && props.tweetWarningMsg && <h5 className="bg-error">{props.tweetWarningMsg}</h5>}

        {(props.isTweetIdValid || props.isTweetIdMissingInfo) &&
          <div>
            <h6>Preview</h6>
            <TwitterTweetEmbed tweetId={props.tweetId} options={{conversation: 'none' }}/>
          </div>
        }
        {!(props.isTweetIdValid || props.isTweetIdMissingInfo) &&
          <div className="empty">
            <div className="empty-icon">
              <i className="icon icon-search"></i>
            </div>
            <p className="empty-title h5">Preview</p>
            <p className="empty-subtitle">Your tweet will be previewed here.</p>
          </div>
        }
      </div>

    </div>
  );

}

CgMintStep2.propTypes = {
  gramId: PropTypes.number.isRequired,
  isTweetIdChecked: PropTypes.bool.isRequired,
  isTweetIdValid: PropTypes.bool.isRequired,
  handleTweetChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
}

export default CgMintStep2;
