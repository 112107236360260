import React from 'react'
import { Link } from "react-router-dom";

import Header from './header.jsx'
import Footer from './footer.jsx'
import Divider from './divider.jsx'

export default function ErrorBoundary() {
  return (
    <div id="pageError">
      <Header />
      <h1>Error: Route Not Found</h1>
      <Link to="/">back to homepage</Link>
      <Divider />
      <Footer />
    </div>
  );
}
