import React from 'react'

export default function Footer() {
  return (
    <div id="sectionFooter">
      <p>
        Marketplaces<br/>
        <a href="https://opensea.io/collection/dotmaps">Opensea</a><br/>
        <a href="https://looksrare.org/collections/0x7f99A36556a05C80E2C30D8663a7Ff6eC69022cB">Looksrare</a><br/>
        <a href="https://foundation.app/@DotMapsDeployer">Foundation</a><br/>
        <a href="https://physical.dotmaps.wtf/collections/all">Shopify</a>
      </p>

      <p>
        Socials<br/>
        Twitter: @<a href="https://twitter.com/dotmapswtf">dotmapswtf</a><br/>
        Instagram: @<a href="https://www.instagram.com/dotmapswtf/">dotmapswtf</a><br/>
        Facebook: @<a href="https://www.facebook.com/dotmapswtf/">dotmapswtf</a>
      </p>

      <p>
        <span>DotMaps built with ❤️ by </span>
        <a href="https://twitter.com/CandyViz">C🅰️ndyViz</a>
      </p>
    </div>
  );
}
