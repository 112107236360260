import React, {useState,useEffect} from 'react'
import PropTypes from 'prop-types';

import { chain, useNetwork } from 'wagmi';

import { parseEther,formatEther } from "@ethersproject/units";

function CgMintStep3(props) {
  const { chain } = useNetwork();

  

  return (
    <div>
      <h3>Final Step</h3>

      <div className="columns">
        <div className="column col-6 col-sm-12">
          <h4>Address</h4>
        </div>
        <div className="column col-6 col-sm-12">
          <p>{props.address}</p>
        </div>
        <div className="column col-6 col-sm-12">
          <h4>Tweet</h4>
        </div>
        <div className="column col-6 col-sm-12">
          <p>
            <mark>{props.tweetId}</mark>
            <span> [<a onClick={props.handleBackToSubmitStep2}>change</a>]</span>
          </p>
        </div>

        <div className="column col-6 col-sm-12">
          <h4>Your Mint Price</h4>
        </div>
        <div className="column col-6 col-sm-12">
          {props.gramMintPrice==0 && <p>Free Mint</p>}
          {props.gramMintPrice!=0 && <p>{formatEther(props.gramMintPrice)}eth</p>}
        </div>
        <div className="column col-6 col-sm-12">
          <h4>Contract</h4>
        </div>
        <div className="column col-6 col-sm-12">
          <p>{props.contractAddress}</p>
        </div>
      </div>

      {!props.isContractPrepared &&
        <div>{props.prepareContractMintError}</div>
      }

      {!props.isMintSuccess &&
        <button className={"btn btn-lg btn-success "+(props.isMintLoading?"loading":"")} onClick={props.handleSubmit}>Mint my Digital CandyGram 🚀🚀🚀🚀🚀</button>
      }

      {props.isMintSuccess && (props.isMintTransFetching||props.isMintTransLoading) &&
        <div className="loading"></div>
      }

      {props.isMintSuccess && props.isMintTransSuccess &&
        <p class="bg-success">Transaction <a target="_blank" href={`${chain.blockExplorers.default.url}/tx/${props.mintTransData?.transactionHash}`}>{props.mintTransData?.transactionHash}</a></p>
      }

      {props.isMintSuccess && props.isMintTransError &&
        <p class="bg-error">Error: {JSON.stringify(props.mintTransError, null, 2)}</p>
      }


    </div>
  );

}

CgMintStep3.propTypes = {
  gramId: PropTypes.number.isRequired,
  tweetId: PropTypes.string.isRequired,
  address: PropTypes.string.isRequired,
}

export default CgMintStep3;
