import React from 'react'
import { Link } from "react-router-dom";

export default function TinyGenesisImage(props) {
  var imgThumb = '/data/'+props.token+'/'+props.token+'-thumb.png';
  var link = '/genesis/'+props.token';

  return (
    <div className="tiny-genesis-image col-2 col-xs-4 col-sm-3">

      <Link to={link}>
        <img className="img-responsive token" src={imgThumb} />
      </Link>
      <div className="token-label">#{props.token}</div>

    </div>
  );
}
