import React from 'react'
import { useInterval } from 'usehooks-ts'

import TwitterLogin from './../../twitter-login.jsx'

export default function CgMintAuthTwitter(props) {
  useInterval(
    () => {
      if(props.authWait){
        props.triggerDataRefresh();
      }
    },
    5000,
  );

  if(props.authWait){
    return <div className="loading loading-lg"></div>;
  }

  return (
    <div>
      <div className="columns">
        <div className="column col-6 col-sm-12">
          <p>
            In order to help you mint this CandyGram we need to request read-only access to your twitter account.  The data will only be kept as long as this mint page is active and will be deleted afterwards.  You can see an example of the permissions in the example image.  To continue click the login to twitter button below.
          </p>
          <div style={{textAlign:"center"}}>
            {props.twitterUserId==0 && <TwitterLogin linkKey={props.linkKey}/>}
            {props.twitterUserId!=0 &&
              <div>
                <h5>Signed in as User:{props.twitterUserId}</h5>
                <button className="btn btn-link" onClick={props.logout}>log out and sign-in as another user</button>
              </div>
            }
          </div>
        </div>
        <div className="column col-mx-auto col-4 col-sm-12">
          <img className="img-responsive" style={{border:"1px solid #5b5b5b",padding:"10px"}} src="/data/candygrams/mint/twitter-auth.png" />
        </div>
      </div>



    </div>
  );
}
