import { useState, useEffect } from 'react';

import { chain, useNetwork,useContractRead } from 'wagmi';

import { useDmContract } from './dm-contract.js'

import cgData from "./../components/cg/candygram-data.json";


export function useGramData(gramId) {
  const PHYSICAL_IMG_PATH = "/data/candygrams/pcg/"
  const [gramName, setGramName] = useState("");

  // Off-chain
  const [isGramOffChainDataFetched, setIsGramOffChainDataFetched] = useState(false);
  const [isGramFound, setIsGramFound] = useState(false);
  const [isGramPublished, setIsGramPublished] = useState(false);
  const [gramDescription, setGramDescription] = useState("");
  const [gramFrontImage, setGramFrontImage] = useState(PHYSICAL_IMG_PATH + "next.png");
  const [gramBackImage, setGramBackImage] = useState(PHYSICAL_IMG_PATH + "next.png");

  // On-Chain
  const [isGramOnChain, setIsGramOnChain] = useState(false);
  const [gramCurrentToken, setGramCurrentToken] = useState(0);
  const [gramTokenLimit, setGramTokenLimit] = useState(0);
  const [gramTokenCount, setGramTokenCount] = useState(0);
  const [gramMintPrice, setGramMintPrice] = useState(0);
  const [gramFreeMintCount, setGramFreeMintCount] = useState(0);
  const [gramStartBlock, setGramStartBlock] = useState(0);
  const [gramStopBlock, setGramStopBlock] = useState(0);

  const {
    address:contractAddress,
    abi:contractAbi
  } = useDmContract("cgToken");

//   struct Gram {
//     Counters.Counter gramTokenCounter;
//     string name;
//     uint256 currentToken;
//     uint256 mintPrice;
//     uint16 tokenLimit;
//     uint256 startBlock;
//     uint256 stopBlock;
//     uint8 freeMintCount;
//   }
  const {
    error:gramOnChainDataError,
    isError:isGramOnChainDataError,
    isFetched:isGramOnChainDataFetched
  } = useContractRead({
    address: contractAddress,
    abi: contractAbi,
    functionName: 'gram',
    args: [gramId],
    onSuccess(data) {
      //console.log('Success', data)
      if(data.name.length>0){
        setIsGramOnChain(true);
        setGramName(data.name);
        setGramCurrentToken(data.currentToken);
        //setGramTokenLimit(data.tokenLimit);
        // if(data[0].length>0){
        //   setGramTokenCount(data[0][0].toNumber());
        // }
        setGramTokenCount(data.currentToken);
        //setGramMintPrice(data.mintPrice);
        setGramFreeMintCount(data.freeMintCount);
        setGramStartBlock(data.startBlock);
        setGramStopBlock(data.stopBlock);
      }else{
        setIsGramOnChain(false)
      }
    }
  });



  useEffect(() => {
    cgData.physicals.forEach(cg => {
      if(cg.id == gramId){
        setIsGramFound(true);
        setIsGramPublished(cg.live);
        setGramFrontImage(PHYSICAL_IMG_PATH + cg.front);
        setGramBackImage(PHYSICAL_IMG_PATH + cg.back);
        setGramDescription(cg.description);
        setGramName(cg.name);
      }
    });
    setIsGramOffChainDataFetched(true);
  });


  const out = {
    gramName: gramName,
    isGramDataFetched: (isGramOffChainDataFetched && isGramOnChainDataFetched),
    // Off-Chain
    isGramOnChainDataFetched: isGramOnChainDataFetched,
    isGramFound: isGramFound,
    isGramPublished: isGramPublished,
    gramDescription: gramDescription,
    gramFrontImage: gramFrontImage,
    gramBackImage: gramBackImage,
    // On-Chain
    gramOnChainDataError: gramOnChainDataError,
    isGramOnChainDataError: isGramOnChainDataError,
    isGramOnChainDataFetched: isGramOnChainDataFetched,
    isGramOnChain: isGramOnChain,
    gramCurrentToken: gramCurrentToken,
    //gramTokenLimit: gramTokenLimit,
    gramTokenCount: gramTokenCount,
    //gramMintPrice: gramMintPrice,
    gramFreeMintCount: gramFreeMintCount,
    gramStartBlock: gramStartBlock,
    gramStopBlock: gramStopBlock,
  };

  console.log(`useGramData(${gramId})`,out);
  return out;
}



export function useIsGramMintable(gramId){
  const {
    address:contractAddress,
    abi:contractAbi
  } = useDmContract("cgMinter");

  const {
    data: isGramMintable,
    isError:isGramMintableError,
    isFetched:isGramMintableFetched
  } = useContractRead({
    address: contractAddress,
    abi: contractAbi,
    functionName: 'isGramMintable',
    args: [gramId],
    onSuccess(data) {
      console.log('isGramMintable():', data)
    },
    onError(error) {
      console.log(error);
    }
  });

  const out = {
    isGramMintable: !!isGramMintable,
    isGramMintableError: isGramMintableError,
    isGramMintableFetched: isGramMintableFetched,
  };

  //console.log(`useWatchIsGramMintable(${gramId})`,out);
  return out;
}


export function useWatchGramMintPrice(gramId){
  const {
    address:contractAddress,
    abi:contractAbi
  } = useDmContract("cgMinter");

  const {
    data: gramMintPrice,
    isError:isGramMintPriceError,
    isFetched:isGramMintPriceFetched
  } = useContractRead({
    address: contractAddress,
    abi: contractAbi,
    functionName: 'mintPrice',
    args: [gramId,false],
    watch: true,
    onSuccess(data) {
      //console.log('getGramMintPrice():', data)
    },
    onError(error) {
      console.log(error);
    }
  });

  const out = {
    gramMintPrice: gramMintPrice,
    isGramMintPriceError: isGramMintPriceError,
    isGramMintPriceFetched: isGramMintPriceFetched
  };

  //console.log(`useWatchGramMintPrice(${gramId})`,out);
  return out;
}


export function useGramTokenData(tokenId){
  const [tokenData, setTokenData] = useState({});
  const [tokenName, setTokenName] = useState("");
  const [tokenDescription, setTokenDescription] = useState("");
  const [tweetId, setTweetId] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [gramId, setGramId] = useState(0);
  const [fgImage, setFgImage] = useState("");
  const [bgImage, setBgImage] = useState("");
  const [rotation, setRotation] = useState("");
  const [colorR, setColorR] = useState(0);
  const [colorG, setColorG] = useState(0);
  const [colorB, setColorB] = useState(0);
  const [adjB, setAdjB] = useState(0);
  const [adjC, setAdjC] = useState(0);
  const [adjG, setAdjG] = useState(0);
  const [tiled, setTiled] = useState(0);
  const [isRevealed, setIsRevealed] = useState(false);

  const [mintBlock, setMintBlock] = useState(0);
  const [lastTransferBlock, setLastTransferBlock] = useState(0);
  const [renderBlock, setRenderBlock] = useState(0);
  const [previousToken, setPreviousToken] = useState(0);

  const [totalTokens, setTotalTokens] = useState(0);


  const {
    address:contractAddress,
    abi:contractAbi
  } = useDmContract("cgToken");

  const {error:tokenUriError, isError:isTokenUriError, isFetched:isTokenUriFetched} = useContractRead({
    address: contractAddress,
    abi: contractAbi,
    functionName: 'tokenURI',
    args: [tokenId],
    onSuccess(dataStr) {
      const dataJson =  atob(dataStr.replace("data:application/json;base64,",""));
      const dataObj = JSON.parse(dataJson);
      setTokenData(dataObj);

      console.log('Success', dataObj)

      setTokenName(dataObj.name);
      setTokenDescription(dataObj.description);
      setTweetId(dataObj.tweet_id);
      setTotalTokens(dataObj.total_token_count);
      parseImageUrl(dataObj.image);
    },
  })

  function parseImageUrl(imageUrl) {
    setImageUrl(imageUrl)
    const imgData = imageUrl.replace("https://dotmaps.wtf/workers/cg/v1/","").replace(`/${tokenId}.png`,"");

    const imagePieces = imgData.split("_");
    if(imagePieces.length==11){
      setIsRevealed(true);
      setGramId(imagePieces[0]);
      setFgImage(imagePieces[1]);
      setRotation(imagePieces[2]);
      setBgImage(imagePieces[3]);
      setColorR(imagePieces[4]);
      setColorG(imagePieces[5]);
      setColorB(imagePieces[6]);
      setAdjB(imagePieces[7]);
      setAdjC(imagePieces[8]);
      setAdjG(imagePieces[9]);
      setTiled(imagePieces[10]);
    }else if(imagePieces.length==6){
      setIsRevealed(false);
      setGramId(imagePieces[0]);
    }
  }


  const out = {
    tokenUriError: tokenUriError,
    isTokenUriError: isTokenUriError,
    isTokenUriFetched: isTokenUriFetched,
    tokenName: tokenName,
    tokenDescription: tokenDescription,
    tokenTweetId: tweetId,
    tokenImageUrl: imageUrl,
    gramId: gramId,
    tokenFgImageName: fgImage,
    tokenBgImageName: bgImage,
    tokenRotation: rotation,
    tokenColorR: colorR,
    tokenColorG: colorG,
    tokenColorB: colorB,
    tokenAdjB: adjB,
    tokenAdjC: adjC,
    tokenAdjG: adjG,
    tokenTilesPerSide: tiled,
    isTokenRevealed: isRevealed,

    totalTokens: totalTokens,

    tokenMintBlock: mintBlock,
    tokenLastTransferBlock: lastTransferBlock,
    tokenRenderBlock: renderBlock,
    tokenPreviousToken: previousToken,
  }

  //console.log(`useGramTokenData(${tokenId})`,out);
  return out;
}
