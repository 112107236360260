import React, {useState,useEffect} from 'react'
import {useParams,useNavigate,Link} from "react-router-dom"

import { useContractRead } from 'wagmi'
//import candygramAbi from './../../../abi/candygram-abi.json';

import { parseEther,formatEther } from "@ethersproject/units";

import { useDmContract } from './../../hooks/dm-contract.js'
import { useGramData } from './../../hooks/gram-data.js'

import Navbar from './../navbar.jsx'
import Footer from './../footer.jsx'
import Divider from './../divider.jsx'
import Parallax from './../parallax.jsx'

import CgSampleGallery from './sample-gallery.jsx'

import cgData from "./candygram-data.json";

const logoOS = new URL('../../images/opensea-logo.png', import.meta.url);
const logoEtherscan = new URL('../../images/etherscan-logo.png', import.meta.url);
const logoLooksrare = new URL('../../images/looksrare-logo.png', import.meta.url);

export default function CgPhysical() {
  let params = useParams();
  const navigate = useNavigate();


  const {address:contractAddress} = useDmContract("candygram");

  const {
    gramName,
    isGramDataFetched,
    isGramFound,
    isGramPublished,
    gramDescription,
    gramFrontImage,
    gramBackImage,
    gramOnChainDataError,
    isGramOnChainDataError,
    isGramOnChainDataFetched,
    isGramOnChain,
    gramCurrentToken,
    //gramTokenLimit,
    gramTokenCount,
    //gramMintPrice,
    gramFreeMintCount,
    gramStartBlock,
    gramStopBlock,
  } = useGramData(params.gramId);

  if( isGramOnChainDataError ){
    console.log("ERROR useGramData()",gramOnChainDataError);
  }

  let frontImg = `/data/candygrams/pcg/${gramFrontImage}`
  let backImg = `/data/candygrams/pcg/${gramBackImage}`
  let openseaGramUrl = `https://opensea.io/collection/candygram?search[stringTraits][0][values][0]=${gramName}&search[sortAscending]=false&search[sortBy]=CREATED_DATE&search[stringTraits][0][name]=Origin`;
  let openseaCurrentTokenUrl = `https://opensea.io/assets/ethereum/${contractAddress}/${gramCurrentToken}`;
  let looksrareCurrentTokenUrl = `https://looksrare.org/collections/${contractAddress}/${gramCurrentToken}`;
  let currentTokenUrl = `/candygram/token/${gramCurrentToken}`;

  let content = <div className="loading loading-lg"></div>;


  if(isGramPublished){
    content = (
      <>
        <div className="columns">
          <div className="col-6 col-sm-12">
            <h3 className="bg-dark" style={{padding:"5px",textAlign:"center"}}>{gramName}</h3>
            <p dangerouslySetInnerHTML={{ __html:gramDescription}} />
            {isGramOnChain && gramCurrentToken>0 &&
              <>
                <p class="bg-primary">
                  <Link to={currentTokenUrl}>Current Token: #{gramCurrentToken}</Link> -

                  <a href={openseaCurrentTokenUrl}>
                    <img src={logoOS} style={{height:"15px",width:"15px",margin:"0px 5px",verticalAlign:"middle"}}/>
                  </a>
                  <a href={looksrareCurrentTokenUrl}>
                    <img src={logoLooksrare} style={{height:"15px",width:"15px",margin:"0px 5px",verticalAlign:"middle"}}/>
                  </a>
                </p>
                <p class="bg-secondary">Total Tokens: {gramTokenCount}</p>
              </>
            }
            {isGramOnChain && gramCurrentToken==0 &&
              <p className="bg-secondary">This CandyGram is waiting for its first mint...</p>
            }
            {!isGramOnChain &&
              <p className="bg-warning">This CandyGram is not yet availible on-chain.</p>
            }
          </div>
          <div className="col-3 col-sm-6" style={{padding:"10px"}}>
            <Parallax img={gramFrontImage} caption="Front"/>
          </div>
          <div className="col-3 col-sm-6" style={{padding:"10px"}}>
            <Parallax img={gramBackImage} caption="Back"/>
          </div>
        </div>
        <CgSampleGallery count={4}
                         colStyle="col-3 col-sm-6"
                         gramId={params.gramId}
                         header="Random Sample Tokens"
                         subheader="Digital Tokens are generated deterministically based on on-chain characteristics during mint and reveal - below is a randomly generated sample of what they may look like."
        />
      </>
    );
  }else if(isGramFound){
    content = (
      <div className="empty">
        <div className="empty-icon">🥰</div>
        <p className="empty-title h5">Coming Soon</p>
        <p className="empty-subtitle">CandyGram #{params.gramId} is still being cut, painted and poured.</p>
      </div>
    )
  }else{
    content = (
      <div className="empty">
        <div className="empty-icon">😭</div>
        <p className="empty-title h5">Not Found</p>
        <p className="empty-subtitle">CandyGram #{params.gramId} doesn't exist.</p>
      </div>
    )
  }


  return (
    <div>
      <Navbar />
      <h1>CandyGram #{params.gramId}</h1>

      {!isGramDataFetched && <div className="loading loading-lg"></div>}

      {isGramDataFetched && content}

      <Divider />
      <Footer />
    </div>
  );
}
