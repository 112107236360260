import * as React from 'react';
import { Link } from "react-router-dom";

import TinyGenesisGallery from './tiny-genesis-gallery.jsx'
import PhysicalGenesisGallery from './physical-genesis-gallery.jsx'

const imgOpensea = new URL('../images/dotmap-opensea.png', import.meta.url);
const imgShirts = new URL('../images/dotmap-shirts-featured-banner.png', import.meta.url);

export default function Intro() {
  return (
    <div>

      <h4 className="pt-2">Physical</h4>

      <PhysicalGenesisGallery />

      <h4 className="pt-2">Digital</h4>

      <TinyGenesisGallery />






    </div>
  );
}
