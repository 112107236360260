import React, {useState,useEffect} from 'react'
import seedrandom from 'seedrandom';

import Navbar from './../navbar.jsx'
import Footer from './../footer.jsx'
import Divider from './../divider.jsx'

import CgSampleGallery from './sample-gallery.jsx'


export default function CgSamples() {


  return (
    <div id="pageBgPatterns">
      <Navbar />
      <h1>CandyGram Art Samples</h1>


      <CgSampleGallery colStyle="col-3 col-sm-2"
                       count={16}
                       header="Randomly generated digital tokens"
                       subheader="Digital Tokens are generated deterministically based on on-chain characteristics during mint and reveal - below is a randomly generated sample of what they may look like."/>


      <Divider />
      <Footer />
    </div>
  );
}
