import React from 'react'

import { ConnectButton } from '@rainbow-me/rainbowkit';

export default function CgMintConnectWallet(props) {

  return (
    <div className="columns">
      <div className="column col-12">
        <p>
          In order to mint a digital token you will need to connect to your ethereum wallet.
        </p>
      </div>
      <div className="column col-mx-auto col-4" style={{textAlign:"center",padding:"15px"}}>
        <ConnectButton label="Connect Wallet to Mint" />
      </div>
      <div className="column col-12">
        <p className="text-warning" style={{border:"1px solid #ffb700",padding:"5px",margin:"20px"}}>
          Security note: We would highly suggest you connect using use a burner or minting wallet.  There will be an option to send the minted NFT to any wallet you would like but the payment should come from a wallet that has just enough eth to mint.
        </p>
      </div>

    </div>
  );
}
