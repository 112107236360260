import React from 'react'
import { Link } from "react-router-dom";

import { ConnectButton } from '@rainbow-me/rainbowkit';
//import { ConnectKitButton } from "connectkit";

const logoOS = new URL('../images/opensea-logo.png', import.meta.url);
const logoTwitter = new URL('../images/twitter-logo.png', import.meta.url);

export default function Navbar() {
  return (
    <header className="navbar" id="navbar">
      <section className="navbar-section">
        <Link to="/" className="navbar-brand mr-2">
          <h3 className="hide-sm">DotMaps</h3>
          <h1 className="show-sm">⦾</h1>
        </Link>

      </section>
      <section className="navbar-center">
        <a class="btn btn-link" href="https://physical.dotmaps.wtf/collections/t-shirts">Shirts</a>
        <a class="btn btn-link" href="https://physical.dotmaps.wtf/collections/hats">Hats</a>
        <a class="btn btn-link" href="https://physical.dotmaps.wtf/collections/hoodies">Hoodies</a>
        <Link to="/genesis" className="btn btn-link">Digital</Link>
      </section>


      <section className="navbar-section hide-sm">
        <a href="http://twitter.com/dotmapswtf" className="btn btn-link"><img src={logoTwitter} /></a>
        <a href="https://opensea.io/collection/dotmaps" className="btn btn-link"><img src={logoOS} /></a>
      </section>

    </header>
  );
}

// <ConnectButton accountStatus="avatar" showBalance={false} chainStatus="none"/>

// <section className="navbar-section hide-sm">
//   <ConnectButton showBalance={false}
//                  chainStatus={{smallScreen:'none',largeScreen:'icon'}}
//                  accountStatus={{smallScreen:'avatar',largeScreen:'address'}} />
// </section>
