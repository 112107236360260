import * as React from 'react';
import { Link } from "react-router-dom";


export default function Parallax(props) {
  const maxWidth = props.maxWidth ? props.maxWidth : "";
  const maxHeight = props.maxHeight ? props.maxHeight : "";
  return (
    <>
      <figure className="figure" style={{maxWidth:maxWidth,maxHeight:maxHeight,display: "block"}}>
        <div className="parallax">
          <div className="parallax-top-left"></div>
          <div className="parallax-top-right"></div>
          <div className="parallax-bottom-left"></div>
          <div className="parallax-bottom-right"></div>
          <div className="parallax-content">
            <div className="parallax-front">
              {props.children}
            </div>
            <div className="parallax-back">
              <img className="img-responsive" src={props.img}/>
            </div>
          </div>
        </div>
        {props.caption && props.link &&
          <figcaption className="figure-caption text-center"><Link to={props.link}>{props.caption}</Link></figcaption>}
        {props.caption && !props.link &&
          <figcaption className="figure-caption text-center">{props.caption}</figcaption>}

      </figure>
    </>
  );

}
