{
  "physicals": [
    {
      "id": 1,
      "live": true,
      "name": "AF3408",
      "front": "1-front.png",
      "back": "1-back.png",
      "description": "This CandyGram is based on <a href='https://opensea.io/assets/ethereum/0x123b30e25973fecd8354dd5f41cc45a3065ef88c/3408' target='_blank'>AlienFren #3408</a>. AF3408 connects to me as a candy lover with the double candy trait of Gummy Worms and Gumballs. Alien Frens is building the biggest group of frens in web3.  To find out more about AlienFrens follow <a href='https://twitter.com/alienfrens' target='_blank'>@alienfrens</a> on twitter or tune in to <a href='https://twitter.com/frensradio_' target='_blank'>@frensradio_</a>.",
    },
    {
      "id": 2,
      "live": true,
      "name": "LP64",
      "front": "2-front.png",
      "back": "2-back.png",
      "description": "This CandyGram is based on <a href='https://opensea.io/assets/ethereum/0x495f947276749ce646f68ac8c248420045cb7b5e/2418308708368151502970757193419323657648235153451525112823571606482865618945' target='_blank'>Little Pill #64</a>.  Little Pills is a collection created by <a href='https://twitter.com/mintmaker' target='_blank'>@mintmaker</a> supporting mental health and helping to make some of the taboo things about mental health ok to talk about. To find out more about Little Pills follow <a href='https://twitter.com/littlepillsnft' target='_blank'>@littlepillsnft</a> on twitter."
    },
    {
      "id": 3,
      "live": true,
      "name": "OCM7403",
      "front": "3-front.png",
      "back": "3-back.png",
      "description":"This CandyGram is based off of <a href='https://opensea.io/assets/ethereum/0x960b7a6bcd451c9968473f7bbfd9be826efd549a/7403' target='_blank'>OnChainMonkey #7403</a>.  OnChainMonkeys is a collection generated completely on-chain.  Its contract was a big inspiration for the CandyGram render contract. The tech is first of its kind and the community is all about being a positive place in web3. To find out more about OnChainMonkeys follow <a href='https://twitter.com/OnChainMonkey' target='_blank'>@OnChainMonkey</a> on twitter. !RISE"
    },
    {
      "id": 4,
      "live": true,
      "name": "MA042",
      "front": "4-front.png",
      "back": "4-back.png",
      "description": "This CandyGram is based on Melancholy Android #042. The androids are a 1/1 collection by @OGRE_3D. "
    },
    {
      "id": 5,
      "live": true,
      "name": "AF6091",
      "front": "5-front.png",
      "back": "5-back.png",
      "description": "This CandyGram is based on AlienFren #6091. Another gummy worm eyes trait."
    },
    {
      "id": 6,
      "live": true,
      "name": "3L36916",
      "front": "6-front.png",
      "back": "6-back.png"
    },
    {
      "id": 7,
      "name":"TD918",
      "live": true,
      "front": "7-front.png",
      "back": "7-back.png"
    },
    {
      "id": 8,
      "name":"FF6571",
      "live": true,
      "front": "8-front.png",
      "back": "8-back.png"
    },
    {
      "id": 9,
      "name":"MoC492",
      "live": true,
      "front": "9-front.png",
      "back": "9-back.png"
    },
    {
      "id": 10,
      "live": false,
      "name":"KRM10"
    },
    {
      "id": 11,
      "live": false,
      "name":"FL5128"
    },
    {
      "id": 12,
      "live": false,
      "name":"BB5887"
    },
    {
      "id": 13,
      "live": false,
      "name": "RS2628"
    },
    {
      "id": 14,
      "live": false,
      "name":"LN164"
    },
    {
      "id": 15,
      "live": false,
      "name":"AFE8015"
    },
    {
      "id": 16,
      "live": false,
      "name":"HL893"
    },
  ],
  "bg": [
    "Ability",
    "Country",
    "Goal",
    "Meaning",
    "Problem",
    "Television",
    "Activity",
    "Data",
    "Growth",
    "Meat",
    "Product",
    "Temperature",
    "Addition",
    "Decision",
    "Health",
    "Media",
    "Quality",
    "Thanks",
    "Analysis",
    "Definition",
    "History",
    "Medicine",
    "Reading",
    "Theory",
    "Area",
    "Department",
    "Idea",
    "Method",
    "Reality",
    "Thing",
    "Army",
    "Development",
    "Income",
    "Moment",
    "Road",
    "Thought",
    "Art",
    "Direction",
    "Industry",
    "Mood",
    "Safety",
    "Truth",
    "Article",
    "Disk",
    "Information",
    "Movie",
    "Science",
    "Unit",
    "Audience",
    "Distribution",
    "Instance",
    "Nation",
    "Security",
    "University",
    "Basis",
    "Driver",
    "Internet",
    "Nature",
    "Series",
    "User",
    "Bird",
    "Economics",
    "Knowledge",
    "News",
    "Shopping",
    "Variety",
    "Blood",
    "Education",
    "Lake",
    "Night",
    "Skill",
    "Video",
    "Boyfriend",
    "Energy",
    "Language",
    "Organization",
    "Society",
    "War",
    "Camera",
    "Equipment",
    "Law",
    "Oven",
    "Software",
    "Week",
    "Cell",
    "Event",
    "Length",
    "Paper",
    "Soup",
    "Wood",
    "Chemistry",
    "Exam",
    "Library",
    "People",
    "Story",
    "World",
    "Child",
    "Fact",
    "Location",
    "Person",
    "Strategy",
    "Writing",
    "Combination",
    "Failure",
    "Love",
    "Philosophy",
    "Student",
    "Community",
    "Family",
    "Management",
    "Physics",
    "Success",
    "Computer",
    "Fishing",
    "Marketing",
    "Player",
    "System",
    "Context",
    "Food",
    "Marriage",
    "Policy",
    "Teacher",
    "Control",
    "Freedom",
    "Math",
    "Power",
    "Technology",
    "Depth"
  ],
  "fg": [
    "Raw",
    "Empty",
    "Build",
    "Growth",
    "Abstract",
    "Polish",
    "Color",
    "Complete",
    "Point",
    "Pixel",
    "Crystal",
    "Ghost",
    "Gradient",
    "Oil",
    "Shape",
    "Texture",
    "Blocks",
    "Burlap",
    "Charcoal",
    "Chrome",
    "Cutout",
    "Edge",
    "Flash",
    "Glass",
    "Grass",
    "Lines",
    "Pills",
    "Plastic",
    "Text",
    "Trace",
    "Water",
    "Wave"
  ],
  "traits": [
    "Wood",
    "Chrome",
    "Blocks",
    "Edges",
    "Grass",
    "Plastic",
    "Waves",
    "Color",
    "Gradient",
    "Glass",
    "Pen",
    "Stamp",
    "Neon",
    "Crosshatch",
    "BasRelief",
    "Accented",
    "Water",
    "Chalk",
    "Plaster",
    "Trees"
  ]
}
