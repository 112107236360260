import React, {useState,useEffect} from 'react'
import PropTypes from 'prop-types';

import { TwitterTweetEmbed } from 'react-twitter-embed'
import { useInterval } from 'usehooks-ts'

import Bcbcbh from './bcbcbh.jsx'



export default function CandyGramMintError(props) {
  return (
    <div>
      <h5 className="bg-error">⚠️ {props.mintError}</h5>
      <p>
        There are many reason an error may have occured when using this CandyGram Mint Link. If you are having
        a problem please contact <a href="https:/twitter.com/CandyViz">@CandyViz</a> on twitter.
      </p>
      <Bcbcbh />

    </div>
  );

}

CandyGramMintError.propTypes = {
  mintError: PropTypes.string.isRequired,
}
